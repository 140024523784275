import BaseModel from "../../../common/baseModel";

export default class CustomerOrderModel extends BaseModel {

    name = "客户订单";
    api = "/order/customerOrder";
    detailApi = "/order/customerOrderDetail";

    quickEdit = false;

    formGrouping = [
        {label: "路线信息", columns: ["consignor", "startArea", "customer", "unloadAreas"]},
        {label: "承运信息", columns: ["driver", "truckNo", "loadUnloadType", "truckLength", "containerType", "tempTruckNo", "loadUnloadType", "additionalPay"]},
        {label: "付款信息", columns: ["totalAmount", "prePay", "arrivePay", "receivePay", "oilCardPay", "factoryOilPay","wannjinOilPay", "deposit", "insuranceFee", "otherFee", "returnBillNo"]}
    ];

    fields() {
        return [
            {
                key: "details",
                visibleInForm: false,
                visibleInGrid: false,
                visibleInDetail: false
            },
            {
                key: "startArea",
                inputControl: "region"
            },
            {
                key: "unloadAreas",
                inputControl: "region",
                inputControlProps: {
                    multiple: true
                }
            },
            {
                key: "returnBillNo",
                placeholder: "多个用英文逗号分割",
                inputControl: "select",
                inputControlProps: {
                    multiple: true
                }
            },
            {
                key: "unloadAreasCount",
                visibleInForm: false
            },
            {
                key: "symbol",
                filterable: {
                    key: "symbol|outBillNo"
                }
            },
            {
                key: "status",
            },
            {
                key: "driver",
                remoteQueryField: "name|mobile",
                filterable: {
                    type: "combo",
                    key: "driverId"
                },
                placeholder: "输入姓名或手机号搜索"
            },
            {
                key: "unloadAreas",
            },
            {
                key: "arrivePay",
                readonly: true
            },
        ];
    }

}
