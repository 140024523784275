import ComConfigure from "../../../common/configure";
import DriverModel from "./DriverModel";

const cc = ComConfigure.getInstance();

export default class DeliveryTruckModel extends DriverModel {

    name = "卡车运力";

    api = "/carrier/deliveryTruck";

    quickEdit = false;

    printableImage = ['idCard', 'driveLicense', 'drivingLicense', 'operateLicenseImage', 'bankCard', 'contract'];

    fields() {
        return super.fields().concat([
            {
                key: "drivingLicenseImage",
                inputControl: "idUpload",
                inputControlProps: {
                    type: "DrivingLicense"
                },
                columnWidth: 6,
            },
            {
                key: "operateLicenseImage",
                inputControl: "idUpload",
                inputControlProps: {
                    type: "Other"
                },
                columnWidth: 6,
            },{
                key: "drivingLicenseImage",
                inputControl: "idUpload",
                inputControlProps: {
                    type: "DrivingLicense"
                },
                columnWidth: 6,
            },
            {
                key: "truckLength",
                inputControl: "select",
                getStaticStore() {
                    return cc.getStaticStore("DeliveryTruckLength");
                }
            },
            {
                key: "containerType",
                inputControl: "select",
                getStaticStore() {
                    return cc.getStaticStore("DeliveryTruckContainerBox");
                }
            }
        ]);
    }

}
