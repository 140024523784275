<template>
    <div>

        <el-button :size="size" @click="showUploadDialog">点击上传或查看</el-button>

        <el-dialog title="文件上传" :visible.sync="uploaderVisible" v-if="uploaderVisible" width="400px" :before-close="handleUploadClose" append-to-body>
            <el-upload
                ref="upload"
                drag
                :accept="acceptMime"
                :on-success="handleUploadSuccess"
                :action="uploadAddress"
                :before-upload="beforeUpload"
                :show-file-list="false"
            >

                <div v-if="cameraOpen">
                    <!--图片展示-->
                    <video ref="video" width="360" height="200" autoplay></video>
                    <canvas ref="canvas" :width="photoWidth" :height="photoHeight" style="opacity: .2"></canvas>
                </div>
                <div v-else>
                    <div class="previewContainer" v-if="currentResource && !cameraOpen">
                        <el-image
                            v-if="currentResource.image"
                            style="width:360px;height:270px"
                            :src="getImageUrl()"
                            fit="contain"></el-image>
                        <div v-else class="text-center">
                            <div class="m-10" style="margin-top: 50px;height:50px;">
                                <file-type-icon :ext="getExt()"></file-type-icon>
                            </div>
                            <small>{{currentResource.name}}</small>
                        </div>

                    </div>
                    <div v-else>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            <div class="bold"> {{ column.label }} </div>
                            将文件拖到此处、粘贴到下方或<em>点击上传</em>
                            <!--                        <div class="mt-10">只能上传jpg/png文件，且不超过500kb</div>-->
                        </div>
                    </div>
                </div>
                <div class="el-upload__tip" slot="tip">
                    <div class="actions-area">
                        <div class="paste-area">
                            <el-input ref="pasteInput"
                                      :disabled="!!currentResource"
                                      :placeholder="`${column.label}粘贴到此处`" size="mini"></el-input>
                        </div>
                        <div class="btns">
                            <el-button-group>
                                <el-button size="mini" @click="callCamera" :disabled="!!currentResource">
                                    {{!cameraOpen ? '开' : '关'}}摄像头
                                </el-button>
                                <el-button size="mini" @click="photograph" :disabled="!cameraOpen">拍照</el-button>
                                <el-popconfirm
                                    class="delete-btn"
                                    @confirm="handleDeleteCurrent"
                                    title="确定要删除这个资源吗？相应的文件会被一起删除"
                                >
                                    <el-button size="mini" slot="reference" :disabled="!currentResource">删除</el-button>
                                </el-popconfirm>
                            </el-button-group>
                        </div>
                    </div>
                </div>
            </el-upload>
        </el-dialog>

        <el-dialog title="图片剪裁" :visible.sync="cropperVisible" :before-close="handleCropperClose" append-to-body>
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                    <vueCropper
                        ref="cropper"
                        :img="cropperOptions.img"
                        :outputSize="cropperOptions.size"
                        :outputType="cropperOptions.outputType"
                        :info="true"
                        :full="cropperOptions.full"
                        :canMove="cropperOptions.canMove"
                        :canMoveBox="cropperOptions.canMoveBox"
                        :original="cropperOptions.original"
                        :autoCrop="cropperOptions.autoCrop"
                        :fixed="cropperOptions.fixed"
                        :fixedNumber="cropperOptions.fixedNumber"
                        :centerBox="cropperOptions.centerBox"
                        :infoTrue="cropperOptions.infoTrue"
                        :fixedBox="cropperOptions.fixedBox"
                    ></vueCropper>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cropperVisible = false">取 消</el-button>
                <el-button type="primary" @click="cropFinish" :loading="loading">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import config from "../../config";
import FileTypeIcon from "./FileTypeIcon";
export default {
    name: "IdUpload",
    components: {FileTypeIcon},
    props: {
        type: {
            type: String,
            required: true
        },
        uploadAddress: {
            type: String,
            default: "/common/uploadedResource/upload"
        },
        value: {},
        acceptMime: {
            default: "image/png,image/jpg,image/jpeg"
        },
        column: {},
        size: {
            default: "small"
        }
    },
    data() {
        return {
            currentResource: null,
            loading: false,

            cameraOpen: false,
            cropperVisible: false,
            cropperOptions: {
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'jpeg', // 裁剪生成图片的格式
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                // autoCropWidth: 300, // 默认生成截图框宽度
                // autoCropHeight: 200, // 默认生成截图框高度
                // fixedBox: true, // 固定截图框大小 不允许改变
                fixed: false, // 是否开启截图框宽高固定比例
                fixedNumber: [7, 5], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },

            fileName: null,
            uploaderVisible: false,

            photoWidth: 4455,
            photoHeight: 2475
        };
    },
    mounted() {
        if(this.value) {
            this.axios.get("/common/uploadedResource/" + this.value).then(res => {
                this.currentResource = res;
            });
        }

        this.column.inputControlProps = this.column.inputControlProps || {
            photoWidth: this.photoWidth,
            photoHeight: this.photoHeight
        };

        this.photoWidth = this.column.inputControlProps.photoWidth || this.photoWidth;
        this.photoHeight = this.column.inputControlProps.photoHeight || this.photoHeight;
    },
    methods: {
        handleUploadClose() {
            if(this.cameraOpen) {
                this.closeCamera();
                this.cameraOpen = false;
            }

            this.uploaderVisible = false;
        },
        showUploadDialog() {

            this.uploaderVisible = true;

            let me = this;

            setTimeout(() => {
                let el = me.$refs.pasteInput.$el;
                el.addEventListener('paste', function (event) {
                    let isChrome = false;
                    if (event.clipboardData || event.originalEvent) {
                        //not for ie11  某些chrome版本使用的是event.originalEvent
                        let clipboardData = (event.clipboardData || event.originalEvent.clipboardData);
                        if (clipboardData.items) {
                            // for chrome
                            let items = clipboardData.items,
                                len = items.length,
                                blob = null;
                            isChrome = true;
                            //items.length比较有意思，初步判断是根据mime类型来的，即有几种mime类型，长度就是几（待验证）
                            //如果粘贴纯文本，那么len=1，如果粘贴网页图片，len=2, items[0].type = 'text/plain', items[1].type = 'image/*'
                            //如果使用截图工具粘贴图片，len=1, items[0].type = 'image/png'
                            //如果粘贴纯文本+HTML，len=2, items[0].type = 'text/plain', items[1].type = 'text/html'
                            //阻止默认行为即不让剪贴板内容在div中显示出来
                            event.preventDefault();
                            //在items里找粘贴的image,据上面分析,需要循环
                            for (let i = 0; i < len; i++) {
                                if (items[i].type.indexOf("image") !== -1) {
                                    blob = items[i].getAsFile();
                                }
                            }
                            if (blob !== null) {
                                let reader = new FileReader();
                                reader.onload = function (event) {
                                    // event.target.result 即为图片的Base64编码字符串
                                    let base64_str = event.target.result
                                    //可以在这里写上传逻辑 直接将base64编码的字符串上传（可以尝试传入blob对象，看看后台程序能否解析）
                                    me.uploadFileFromPaste(base64_str, 'paste', isChrome);
                                }
                                reader.readAsDataURL(blob);
                            }
                        } else {
                            //for firefox
                            setTimeout(function () {
                                //设置setTimeout的原因是为了保证图片先插入到div里，然后去获取值
                                let imgList = document.querySelectorAll('#tar_box img'),
                                    len = imgList.length,
                                    src_str = '',
                                    i;
                                for (i = 0; i < len; i++) {
                                    if (imgList[i].className !== 'my_img') {
                                        //如果是截图那么src_str就是base64 如果是复制的其他网页图片那么src_str就是此图片在别人服务器的地址
                                        src_str = imgList[i].src;
                                    }
                                }
                                me.uploadFileFromPaste(src_str, 'paste', isChrome);
                            }, 1);
                        }
                    }
                })
            });


        },
        getImageUrl() {
            if(!this.currentResource) {
                return null;
            }
            return config.getRemoteBase() + this.currentResource.imageUrl
                + "?token="
                + localStorage.getItem("token")
                + "&_loginSource="
                + this.globalData.loginSource
                ;
        },
        getExt() {
            if(!this.currentResource) {
                return "";
            }
            return this.currentResource.name.split(".").pop();
        },
        uploadFileFromPaste(file) {
            let a = this.dataURLtoBlob(file);
            let b = this.blobToFile(a);
            const upload = this.$refs.upload
            upload.handleStart(b);

            this.handleDeleteCurrent();
            this.beforeUpload(a);
        },
        dataURLtoBlob (dataurl) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime, name: this.column.label });
        },
        blobToFile(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        },
        handleCropperClose() {
            this.loading = false;
            this.cropperVisible = false;
        },
        cropFinish() {
            let me = this;
            this.$refs.cropper.getCropBlob((data) => {
                me.doUpload(data);
            })
        },
        doUpload(data) {
            let me = this;
            me.loading = true;

            let formData = new FormData();
            formData.set("type", this.type);
            formData.set("file", data)
            formData.set("fileName", me.fileName)

            me.axios.post(this.uploadAddress, formData).then(res => {
                me.currentResource = res;
                me.$emit("uploaded", res);
                me.$emit("input", res.id);
                me.cropperVisible = false;
                me.cropperOptions.img = null;
                me.loading = false;
            }, () => {
                me.loading = false;
            });
            return false;
        },
        beforeUpload(blob){
            this.fileName = blob.name;
            if(blob.type.indexOf('image') >= 0) {
                this.cropperVisible = true;
                this.cropperOptions.img = window.URL.createObjectURL(blob);
            } else {
                this.doUpload(blob);
            }

            return false;
        },
        //上传成功，这里把可编辑组件里的内容清空了
        handleUploadSuccess(res) {
            this.loading = false;
            document.getElementById('tar_box').innerHTML='';
            if (res.status === 0){
                this.imgList.push(res.data)
            }
        },
        handleDeleteCurrent() {
            if(!this.currentResource) {
                return;
            }
            this.loading = true;
            this.axios.delete("/common/uploadedResource/" + this.currentResource.id).then(() => {
                this.loading = false;
                this.currentResource = null;
            }, () => {this.loading = false;});
        },

        // 调用摄像头
        callCamera () {

            if(this.cameraOpen) {
                this.closeCamera();
                return;
            }

            this.cameraOpen = true;

            // H5调用电脑摄像头API
            navigator.mediaDevices.getUserMedia({
                video: { width: this.photoWidth, height: this.photoHeight }
            }).then(success => {
                // 摄像头开启成功
                this.$refs['video'].srcObject = success;
                this.$refs['video'].play();
                // 实时拍照效果
            }).catch(error => {
                this.cameraOpen = false;
                this.$message.error('摄像头开启失败，请检查摄像头是否可用！');
                console.error(error)
            })
        },
        // 拍照
        photograph () {
            let ctx = this.$refs['canvas'].getContext('2d'),
                me = this;
            // 把当前视频帧内容渲染到canvas上
            ctx.drawImage(this.$refs['video'], 0, 0, this.photoWidth, this.photoHeight)

            this.$refs['canvas'].toBlob(function(blob) {
                me.closeCamera();
                me.beforeUpload(blob);
            })
        },
        // 关闭摄像头
        closeCamera () {
            if (!this.$refs['video'].srcObject) return
            let stream = this.$refs['video'].srcObject
            let tracks = stream.getTracks()
            tracks.forEach(track => {
                track.stop()
            })
            this.cameraOpen = false;
            this.$refs['video'].srcObject = null
        }
    }
}
</script>

<style scoped lang="scss">
.previewContainer {
    width: 100%;
    height: 100%;
    position: relative;
}
.file-icon {
    font-size: 50px;
    margin-top: 40px;
}
.cropper-content {
    .cropper {
        width: auto;
        height: 600px;
    }
}

.el-upload {
    height: 270px !important;
}
.el-upload-dragger {
    height: 270px !important;
}

.actions-area{
    display: flex;
    flex-flow: row nowrap;
    width: 360px;
    .btns{
        width: 260px;
    }
    .paste-area{
        flex-grow: 1;
        padding-right: 5px;
    }
    .delete-btn {
        float: left;
        display: inline;
    }
}

</style>
