import routerUtils from "@/router/routerUtils";

const routes = [
    {
        path: '/mobile/business',
        component: routerUtils.importPage('pages/business', 'mobile'),
        meta: {
            title: '业务'
        }
    },
    {
        path: '/mobile/business/vinQuery',
        component: routerUtils.importPage('pages/business/vinQuery', 'mobile'),
        meta: {
            title: '车辆查询'
        }
    },
    {
        path: '/mobile/business/queryDriverByVin',
        component: routerUtils.importPage('pages/business/queryDriverByVin', 'mobile'),
        meta: {
            title: '车查单'
        }
    },
    {
        path: '/mobile/business/driverQuery',
        component: routerUtils.importPage('pages/business/driverQuery', 'mobile'),
        meta: {
            title: '驾驶员查询'
        }
    },
    {
        path: '/mobile/business/singleBind',
        component: routerUtils.importPage('pages/business/singleBind', 'mobile'),
        meta: {
            title: '单车绑定'
        }
    },
]

export default routes;
