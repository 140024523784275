<template>
    <div>
        <el-row :gutter="20" v-if="showTableActions">
            <el-col :lg="6" :md="24" :sm="12" :xs="10">
                <div class="grid-content">
                    <slot name="table-title" v-if="modelInstance"> {{modelInstance.name}}管理 </slot>
                </div>
            </el-col>
            <el-col :lg="18" :md="24" :sm="12" :xs="14">
                <div class="after-clear" style="padding: 10px 0">
                    <div class="pull-right">
                        <el-button-group class="ml-10 hidden-sm-and-down">
                            <el-button size="small"
                                       v-if="$scopedSlots['custom-view']"
                                       icon="el-icon-sort" @click="switchView"></el-button>
                            <el-dropdown :hide-on-click="false" trigger="click" v-if="savedLayouts">
                                <el-button size="small">
                                    {{currentLayout.name}}
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="(layout, index) in savedLayouts" :key="index">
                                        <el-button type="text" size="small"
                                                   @click="switchLayout(layout)"
                                                   :disabled="layout.name === currentLayout.name">切换到 {{layout.name || "默认布局"}}</el-button>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-button icon="el-icon-tickets" size="small" @click="layoutSettingsVisible = true"></el-button>
                            <!--                            <el-dropdown :hide-on-click="false" trigger="click">-->
                            <!--                                <el-button icon="el-icon-tickets" size="small"></el-button>-->
                            <!--                                <el-dropdown-menu slot="dropdown">-->
                            <!--                                    <draggable @end="handleColumnDrop">-->
                            <!--                                        <el-dropdown-item v-for="(column, index) in definedColumns" :key="column.key">-->
                            <!--                                            <el-checkbox :checked="column.visible !== false" @change="onVisibleColumnChange(column, index)">-->
                            <!--                                                {{column.label}}-->
                            <!--                                            </el-checkbox>-->
                            <!--                                        </el-dropdown-item>-->
                            <!--                                    </draggable>-->
                            <!--                                </el-dropdown-menu>-->
                            <!--                            </el-dropdown>-->
                        </el-button-group>

                    </div>
                    <el-container class="pull-right ml-10" v-if="quickSearchField">
                        <el-checkbox label="多查" class="mr-10" @change="handleQuickSearchTypeChange" v-model="quickSearchMultiple" />
                        <el-input
                            v-if="!quickSearchMultiple"
                            v-model="quickSearchValue"
                            size="small"
                            class="quick-search"
                            @focus="handleQuickSearchFocus"
                            @change.native="handleQuickSearchChange"
                            placeholder="快速检索"></el-input>
                        <el-select v-model="quickSearchValue"
                                   placeholder="快速检索 按回车多查"
                                   v-else
                                   size="small"
                                   clearable
                                   collapse-tags
                                   multiple
                                   filterable
                                   allow-create
                                   @paste.native.stop="handlePasteQuickSearch"
                                   @change="handleQuickSearchChange"
                                   default-first-option>
                            <el-option v-for="(qs, qi) in quickSearchValue" :key="qi" :value="qs" :label="qs">{{qs}}</el-option>
                        </el-select>
                    </el-container>
                    <div class="pull-right ml-10" v-if="complexSearch">
                        <el-button icon="el-icon-search" size="small" @click="showComplexSearch">查询</el-button>
                    </div>
                    <div class="pull-right">
                        <div class="pull-left">
                            <slot name="default-table-actions">
                                <el-button type="primary" size="small"
                                           class="pull-left hidden-sm-and-down"
                                           v-if="defaultActionsBtn.indexOf('add') >= 0"
                                           @click="goEdit()">新建</el-button>
                                <el-button-group class="ml-10 pull-left">
                                    <el-button size="small" v-if="!$store.getters.isSmallView && importable" @click="showImportDialog">导入</el-button>
                                    <el-button size="small" v-if="!$store.getters.isSmallView && exportable" @click="showExportDialog" :disabled="!itemsList.length">导出</el-button>
                                    <el-button size="small"
                                               v-if="defaultActionsBtn.indexOf('refresh') >= 0"
                                               icon="el-icon-refresh" @click="loadData()"></el-button>
                                    <el-button size="small" v-if="!$store.getters.isSmallView && !trashView" @click="goTrashView(true)">回收站</el-button>
                                    <el-button size="small" v-if="!$store.getters.isSmallView && trashView" @click="goTrashView(false)">浏览</el-button>
                                    <el-popconfirm
                                        class="hidden-sm-and-down"
                                        @confirm="doDelete(selectedItems)"
                                        v-if="defaultActionsBtn.indexOf('delete') >= 0"
                                        :title="`确定要删除这 ${selectedItems.length} 条数据吗？`"
                                    >
                                        <el-button size="small"
                                                   slot="reference"
                                                   :disabled="!selectedItems.length"
                                                   icon="el-icon-delete"></el-button>
                                    </el-popconfirm>

                                </el-button-group>
                            </slot>
                            <slot name="extra-table-actions"></slot>
                        </div>
                    </div>
                </div>


            </el-col>
        </el-row>
        <div class="main-content-container" v-if="listComponent === 'default'">
            <el-table
                v-if="!layoutRendering"
                :height="height"
                v-loading="loading"
                highlight-current-row
                :border="bordered"
                stripe
                ref="elTable"
                :show-summary="showSummary"
                sum-text="-"

                :data="itemsList"
                :size="size"
                :class="{'withColumnFilter': withColumnFilter}"
                :fit="true"
                :summary-method="(params) => summaryMethod(params, modelInstance)"

                @header-dragend="handleHeaderDragEnd"
                @row-dblclick="rowDblclick"
                @selection-change="onSelectChange"
                @expand-change="handleExpandChangeDefault"
                style="width: 100%">
                <el-table-column
                    v-if="allowMultiSelect && !trashView && !$store.getters.isSmallView"
                    align="center"
                    fixed="left"
                    type="selection">
                </el-table-column>
                <div v-if="modelInstance != null && modelInstance.gridExpandable">
                    <el-table-column type="expand" width="40">
                        <template v-slot="props">
                            <slot name="expand-content" :row="props.row"></slot>
                        </template>
                    </el-table-column>
                </div>

                <el-table-column fixed="left" align="center" label="#" width="60" v-if="showRowIndex">
                    <template slot-scope="scope">
                    <span @dblclick="showHideInfo = !showHideInfo">
                        {{(currentPage - 1) * pageSize + scope.$index + 1}}
                        <div v-if="showHideInfo">
                            {{scope.row.id}} [{{scope.row.used ? 1 : 0}}]
                        </div>
                    </span>
                    </template>

                </el-table-column>
                <el-table-column
                    v-for="column in visibleColumns"
                    :key="column.key"
                    :prop="column.key"
                    :sortable="column.sortable"
                    :label="column.label || column.key"
                    :show-overflow-tooltip="true"
                    :show-tooltip-when-overflow="true"
                    :fixed="$store.getters.isSmallView ? false : !!(column.fixed || currentLayout.layoutConfig.fixed[column.key])"
                    :width="currentLayout.layoutConfig.width[column.key] || column.width || 140">

                    <template slot="header" slot-scope="rowScope">
                    <span v-if="withColumnFilter">
                        <slot :name="'custom-column-header-' + column.key" :row="rowScope.row">
                            <table-column-filter
                                v-if="!!column.filterable"
                                @filter-item-changed="handleFilterItemChange"
                                :column="column"></table-column-filter>
                            {{column.label}}
                        </slot>
                    </span>
                        <span v-else>{{column.label}}</span>
                    </template>

                    <template slot-scope="rowScope">
                        <slot :name="'custom-column-' + column.key" :row="rowScope.row">
                            <field-display
                                :api="definedApi"
                                :row="rowScope.row"
                                :column="column"
                            ></field-display>
                        </slot>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="showRowAction"
                    :width="operationWidth"
                    :fixed="(operationFixed === undefined || $store.getters.isSmallView) ? false : operationFixed">
                    <template slot-scope="scope">
                        <div v-if="trashView">
                            <el-popconfirm
                                class="ml-10"
                                @confirm="doRestore(scope.row, scope)"
                                title="确定要恢复这条数据吗？"
                            >
                                <el-button slot="reference" type="text" size="small">恢复</el-button>
                            </el-popconfirm>

                            <el-popconfirm
                                class="ml-10"
                                @confirm="doDestroy(scope.row, scope)"
                                title="确定要彻底删除这条数据吗？ 此操作不可恢复！"
                            >
                                <el-button slot="reference" v-if="!scope.row.used" type="text" size="small">彻底删除</el-button>
                            </el-popconfirm>
                        </div>
                        <div v-else>
                            <slot name="extra-operations" :row="scope.row"></slot>
                            <slot name="operations" :row="scope.row">
                                <el-button v-if="detailPage !== false"
                                           @click="goViewDetail(scope.row, scope)"
                                           type="text" size="small">详情</el-button>
                                <el-button @click="goEdit(scope.row, scope)"
                                           v-if="defaultActionsBtn.indexOf('edit') >= 0"
                                           type="text" size="small">修改</el-button>
                                <el-popconfirm
                                    class="ml-10"
                                    v-if="defaultActionsBtn.indexOf('delete') >= 0"
                                    @confirm="doDelete(scope.row, scope)"
                                    title="确定要删除这条数据吗？"
                                >
                                    <el-button slot="reference" v-if="!scope.row.used" type="text" size="small">删除</el-button>
                                </el-popconfirm>
                            </slot>
                        </div>
                    </template>
                    <template slot="header">
                        <slot name="operation-header">操作</slot>
                    </template>
                </el-table-column>
            </el-table>
        </div>


        <div v-else>
            <slot name="custom-view"
                  :items="itemsList"
                  :allowMultiSelect="allowMultiSelect"
                  :onSelectChange="onSelectChange"
                  :loading="loading"></slot>
        </div>

        <div class="pagination">
            <el-pagination
                v-if="showPager"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[15, 30, 50, 100]"
                :page-size="pageSize"
                :layout="pageLayout"
                :total="totalItems">
            </el-pagination>
        </div>

        <el-dialog  :close-on-click-modal="false" :visible.sync="importDialogVisible" v-if="importDialogVisible" title="导入">
            <el-form label-width="80">
                <el-form-item style="height: 190px;">
                    <el-upload
                        ref="upload"
                        drag
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        :on-success="handleUploadSuccess"
                        :action="importAddress"
                        :before-upload="beforeImport"
                        :show-file-list="false"
                    >
                        <div>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                                将文件拖到此处或 <em>点击上传</em>
                            </div>
                        </div>
                    </el-upload>
                </el-form-item>
                <slot name="extra-import-contents"></slot>
                <el-form-item>
                    <el-button type="primary" size="small"
                               @click="downloadImportTemplate(modelInstance.getApi())">下载导入模板</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-drawer
            :visible.sync="layoutSettingsVisible"
            direction="rtl">
            <template slot="title">
                <span role="heading">{{ currentLayout.name }}</span>
                <el-button size="mini" style="float: right" type="primary" @click="handleSaveTableLayout">保存布局</el-button>
            </template>
            <div class="layout-settings-container">
                <draggable @end="handleColumnDrop">
                    <el-container class="space-between drag-column-item"
                                  v-for="(column, index) in allColumns"
                                  :key="index">
                        <el-checkbox v-model="column.visible" :checked="column.visible !== false">
                            {{column.label}}
                        </el-checkbox>
                        <el-switch active-text="固定"
                                   v-model="column.fixed" />
                    </el-container>
                </draggable>
            </div>
        </el-drawer>

        <item-deail-modal :model-instance="modelInstance"
                          v-if="modelInstance && detailVisible && currentRow"
                          v-model="detailVisible" :raw="currentRow">
            <template v-slot:detail-title="scope">
                <slot name="item-detail-modal-title" :row="scope.row"></slot>
            </template>
            <template v-slot:extra-info="scope">
                <slot name="item-detail-modal-extra-info" :row="scope.row"></slot>
            </template>
        </item-deail-modal>

        <complex-query-window v-if="modelInstance"
                              :filters="filters"
                              @query="handleQuery"
                              v-model="complexSearchVisible"
                              :model-instance="modelInstance" />
    </div>
</template>

<script>

import TableColumnFilter from "../components/TableColumnFilter";
import BaseModel from "../baseModel";
import FieldDisplay from "../components/FieldDisplay";
import Importable from "./Importable";
import draggable from "vuedraggable";
import Exportable from "./Exportable";
import ComplexQueryWindow from "../components/ComplexQueryWindow";
import ItemDeailModal from "@/common/components/ItemDetailModal.vue";

export default {
    name: "BaseTable",
    components: {
        ItemDeailModal,
        ComplexQueryWindow,
        TableColumnFilter, FieldDisplay, draggable
    },
    mixins: [
        Importable, Exportable
    ],
    props: {
        // 数据，当存在 api 时此项无效
        data: [],
        // 列配置
        columns: [],
        showRowIndex: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: undefined
        },
        // 可视列
        showColumnList: {
            type: Array,
            default() {
                return []
            }
        },
        fixedColumns: {
            type: Array,
            default() {
                return []
            }
        },
        bordered: {
            type: Boolean,
            default: true
        },
        // 分页布局
        pageLayout: {
            type: String,
            default: "total, sizes, prev, pager, next, jumper"
        },
        showPager: {
            type: Boolean,
            default: true
        },
        // 后端 api
        api: null,
        // 总数量
        totalNum: {
            default: 0
        },
        // 绑定模型
        model: null,
        // 是否本页编辑
        newPageEdit: {
            default: false
        },
        // 操作按钮固定列，可选 true, false, left, right
        operationFixed: {
            default: false
        },
        // 操作列宽度
        operationWidth: {
            default: 100
        },
        // 是否可查看详情
        detailPage: {
            type: Boolean
        },
        autoLoad: {
            type: Boolean,
            default: true
        },
        // 表格尺寸
        size: {
            default: "mini"
        },
        // 启用列快速筛选
        withColumnFilter: {
            default: true
        },
        // 是否显示表格操作
        showTableActions: {
            type: Boolean,
            default: true
        },
        // 是否显示行操作
        showRowAction: {
            type: Boolean,
            default: true
        },
        // 是否允许多选操作
        allowMultiSelect: {
            type: Boolean,
            default: true
        },
        // 行双击事件
        rowDblclick: {
            type: Function,
            default: () => {
            }
        },
        // 复杂查询
        complexSearch: {
            type: Boolean,
            default: true
        },
        // 默认的查询条件
        defaultQueryParams: {},
        // 可快速查询的字段
        quickSearchField: {
            type: String,
            default: null
        },
        // 行展开
        handleExpandChange: {
            type: Function,
            default: () => {}
        },
        // 默认显示的视图
        defaultListComponent: {
            type: String,
            default: "default"
        },
        // 默认操作按钮显示列表
        defaultActionsBtn: {
            type: Array,
            default: function() {
                return [
                    'add', 'refresh', 'edit', 'delete'
                ];
            }
        },
        tableName: {
            type: String,
            default: "_table",
        },
        // 显示合计
        showSummary: {
            type: Boolean,
            default: false
        },
        // 合计方法
        summaryMethod: {
            type: Function,
            default: function(params, modelInstance) {
                const { columns, data } = params;
                let sums = [];

                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '-';
                        return;
                    }

                    if(column.property
                        && modelInstance.fieldsMap[column.property]
                        && !modelInstance.fieldsMap[column.property].showSummary) {
                        sums[index] = '';
                        return;
                    }


                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = '';
                    }
                });

                return sums.map(item => {
                    if(item <= 0 || item === '-') {
                        return item;
                    }
                    return Math.floor(item).toFixed(2);
                });
            }
        },
        importable: {
            default: () => {
                return false;
            }
        },
        exportable: {
            default: () => {
                return false;
            }
        },
        // 离线模式 不从后端获取数据
        offline: {
            default: () => {
                return false;
            }
        },
    },

    data() {
        return {
            // 数据列表
            itemsList: this.data || [],
            // 列配置
            definedColumns: this.columns || [],
            allColumns: [],
            // 显示的列
            // visibleColumns: [],
            // 后端 api
            definedApi: this.api || null,

            definedParentNode: this.$parent.$parent,

            // 分页配置
            currentPage: 1,
            pageSize: 15,
            totalItems: this.totalNum || 0,

            loading: false,

            // 选中项目
            selectedItems: [],

            modelInstance: null,

            // 过滤器
            filters: {},
            submitedFilters: {},
            quickSearchValue: null,

            // 显示隐藏的信息
            showHideInfo: false,

            // 默认的 list component
            listComponent: this.defaultListComponent,

            // 布局设置是否开启
            layoutSettingsVisible: false,
            // 当前的布局
            currentLayout: {
                layoutConfig: {
                    fieldsList: [],
                    width: {},
                    fixed: {}
                },
                name: "默认布局"
            },
            // 保存的布局
            savedLayouts: [],
            // 回收站
            trashView: false,
            // page inited
            tableLoaded: 0,
            // 高级查询
            complexSearchVisible: false,
            layoutRendering: false,

            currentRow: null,
            detailVisible: false,

            quickSearchMultiple: false
        };
    },

    mounted() {
        this.modelInstance = this.model instanceof BaseModel ? this.model : this.model.getInstance();

        if(!this.api && this.modelInstance != null) {
            this.definedApi = this.modelInstance.listApi || this.modelInstance.getApi();
        }

        this.definedColumns = this.modelInstance.getGridColumns();

        this.savedLayouts = this.globalData.layouts[this.modelInstance.getApi() + this.tableName] || [];
        if(this.savedLayouts.length) {
            this.switchLayout(this.savedLayouts[0], false);
        }

        this.allColumns = this._.cloneDeep(this.definedColumns)
        this.allColumns = this._.sortBy(this.allColumns, item => item._index < 0 ? 99999 : item._index)

        this.definedColumns = this._.sortBy(this.definedColumns, item => item._index)

        // this.loadColumns();
        if(this.autoLoad) {
            this.loadData();
        }
    },
    computed: {
        visibleColumns() {
            let columns = []
            if(this.showColumnList && this.showColumnList.length && this.modelInstance) {
                columns = Array.from(this.showColumnList, item => {
                    return this.modelInstance.fieldsMap[item] || {}
                })
            } else {
                if(this.currentLayout.layoutConfig.fieldsList.length) {
                    columns = Array.from(this.currentLayout.layoutConfig.fieldsList, item => {
                        return this.modelInstance.fieldsMap[item] || {};
                    });
                } else {
                    columns = this.definedColumns.filter(column => {
                        return column.visible !== false;
                    });
                }
            }

            return columns;
        }
    },

    methods: {
        showComplexSearch() {
            this.complexSearchVisible = true;
        },
        goTrashView(trash) {
            this.trashView = trash;
            let options = {};

            if(trash) {
                options._ot = 1;
            }
            this.loadData(options);
        },
        handleSizeChange(size) {
            this.pageSize = size;
            this.loadData();
        },
        handleCurrentChange(current) {
            if(this.currentPage === current) return;
            this.currentPage = current;
            this.loadData();
        },

        switchView() {
            this.listComponent = this.listComponent === "default" ? "custom" : "default"
        },

        loadList(page, pageSize, options) {
            let queryApi = this.queryApi || this.api || this.modelInstance.getApi();

            this.loading = true;

            options = Object.assign(this.defaultQueryParams || {}, {
                page: page || this.currentPage || 1,
                limit: pageSize || this.pageSize || 15
            }, options);

            if(queryApi) {
                return this.axios.get(queryApi, { params: options });
            } else {
                throw("`queryApi` or `api` must defined in data.");
            }
        },

        loadData(options) {
            this.itemsList = [];
            if((this.data && this.data.length) || this.offline) {
                this.itemsList = this.data;
                this.totalItems = this.data.length;
                return;
            }
            let me = this;

            options = options || {};
            if(this.trashView) {
                options._ot = 1;
            }

            Object.keys(this.filters).forEach(filter => {
                let value = this.filters[filter];
                if(this._.isArray(value)) {
                    value = "@" + value.join();
                }
                if(value && value.length) {
                    options[filter] = value;
                }
            });

            me.submitedFilters = options;
            me.loading = true;

            const loadList = typeof this.definedParentNode.loadList === "function" ? this.definedParentNode.loadList : this.loadList;
            return loadList(this.currentPage, this.pageSize, options).then((res) => {
                me.tableLoaded += 1;
                me.itemsList = res.items;
                me.totalItems = res.total;
                // me.$forceUpdate();
                me.loading = false;
            }, () => me.loading = false);

        },
        handleHeaderDragEnd(width, old, column) {
            this.$set(this.currentLayout.layoutConfig.width, column.property, width);
        },
        handleSaveTableLayout() {
            this.$prompt('请输入布局名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: this.currentLayout.name
            }).then(({ value }) => {
                this.$set(this.currentLayout.layoutConfig, "fieldsList", Array.from(
                    this.allColumns.filter(item => item.visible),
                    item => item.key
                ))
                this.allColumns.forEach(item => {
                    this.$set(this.currentLayout.layoutConfig.fixed, item.key, item.fiex || undefined)
                });

                this.layoutSettingsVisible = false;
                this.loading = true;
                this.layoutRendering = true
                this.axios.put("/common/frontEndLayout", {
                    symbol: `${this.modelInstance.getApi()}_table`,
                    name: value || this.currentLayout.name,
                    content: JSON.stringify(this.currentLayout.layoutConfig)
                }).then(() => {
                    this.loading = false
                    this.layoutRendering = false
                }).catch(() => {this.loading = false;});
            })
        },
        switchLayout(layout) {
            this.layoutRendering = true
            this.currentLayout = layout;
            this.currentLayout.name = layout.name || "默认布局";
            this.currentLayout.layoutConfig = this.currentLayout.layoutConfig || {};
            this.currentLayout.layoutConfig.fieldsList = this.currentLayout.layoutConfig.fieldsList || [];
            this.currentLayout.layoutConfig.width = this.currentLayout.layoutConfig.width || {};
            this.currentLayout.layoutConfig.fixed = this.currentLayout.layoutConfig.fixed || {};

            this.definedColumns.forEach(item => {
                this.$set(item, "_index", this.currentLayout.layoutConfig.fieldsList.indexOf(item.key));
                if(this.currentLayout.layoutConfig.fieldsList.length > 0 && item._index < 0) {
                    this.$set(item, "visible", false);
                }
                if(undefined !== this.currentLayout.layoutConfig.fixed[item.key]) {
                    this.$set(item, "fixed", this.currentLayout.layoutConfig.fixed[item.key]);
                }
                if(undefined !== this.currentLayout.layoutConfig.width[item.key]) {
                    this.$set(item, "width", this.currentLayout.layoutConfig.width[item.key]);
                }
            });
            this.layoutRendering = false
        },
        // saveTableLayout: __.debounce(() => {
        //     this.handleSaveTableLayout();
        // }, 10000),

        onSelectChange(selected) {
            this.selectedItems = selected;
            this.$emit("select-change", selected);
        },

        goEdit(row) {
            this.definedParentNode.goEdit(row);
        },
        goViewDetail(row) {
            this.currentRow = row;
            this.detailVisible = true;
            this.$emit("detail-visible-change", row);
        },
        doDelete(row) {
            this.definedParentNode.doDelete(row);
        },

        doRestore(row) {
            this.definedParentNode.doRestore(row);
        },

        doDestroy(row) {
            this.definedParentNode.doDestroy(row);
        },

        handleQuery(queryData) {
            this.complexSearchVisible = false
            this.loadData(queryData)
        },
        handleFilterItemChange(column, value) {
            if(value === null || value === undefined || String(value).length === 0 || String(value) === "%%") {
                this.$set(this.filters, column, undefined);
            } else {
                this.$set(this.filters, column, value);
            }

            this.currentPage = 1;
            this.loadData();
        },

        handleQuickSearchTypeChange() {
            this.quickSearchValue = null
        },
        handlePasteQuickSearch(e) {
            const raw = e.clipboardData.getData('text')
                .replaceAll("\r", "")
                .split("\n")
                .map(item => {
                    const match = item.match(/[0-9A-Za-z]+/);
                    return match ? match[0] : ""
                })
            this.quickSearchValue = raw

            this.handleQuickSearchChange(e)

            e.target.blur()
        },
        handleQuickSearchChange(e) {
            if(!this.quickSearchMultiple) {
                e.target.select();
                let value = this.quickSearchValue;
                if(value.indexOf("%") < 0) {
                    value = `%${value}%`;
                }
                this.handleFilterItemChange(this.quickSearchField, value);
            } else {
                const me = this;

                me.handleFilterItemChange(me.quickSearchField, `$${me.quickSearchValue.join()}`);
                // me._.debounce(function(){
                //     console.log(111)
                //     me.handleFilterItemChange(me.quickSearchField, `$${me.quickSearchValue.join()}`);
                // }, 500)
            }
        },
        handleQuickSearchFocus(e) {
            e.target.select()
        },

        handleExpandChangeDefault(row, expanded) {
            if(expanded.indexOf(row) < 0) {
                return;
            }
            this.handleExpandChange(row, expanded);
        },

        resetFilters() {
            this.filters = {};
            this.loadData();
        },
        handleColumnDrop(e) {
            let {oldIndex, newIndex} = e;
            let columns = this._.cloneDeep(this.allColumns)
            const tmp = columns.splice(oldIndex, 1)[0];
            columns.splice(newIndex,0, tmp);

            this.allColumns = []
            this.$nextTick(() => {
                this.allColumns = columns;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.pagination {
    margin: 10px auto;
    text-align: center;
}

.el-table__empty-block {
    min-height: 100px;
}

.grid-content {
    padding: 10px;
}

.view-size-small .quick-search {
    width: 70px;
}
.layout-settings-container {
    padding-bottom: 20px;
}
.drag-column-item {
    padding: 3px 20px;
    border-bottom: 1px solid #e2e2e2;

    .el-switch {
        margin-top: 4px;
    }
}
.el-table--scrollable-x .el-table__body-wrapper {
    z-index: 1;
}
</style>
