import BaseModel from "../../../common/baseModel";

export default class ItemTagModel extends BaseModel {

    name = "项目标签";
    api = "/common/itemTag";

    fields() {
        return [
            {
                key: "displayType",
                inputControl: "select",
                options: [
                    {id: "default", name: "默认"},
                    {id: "primary", name: "主要"},
                    {id: "success", name: "成功"},
                    {id: "info", name: "信息"},
                    {id: "danger", name: "危险"},
                    {id: "warning", name: "警告"},
                ]
            },
            {
                key: "displayTheme",
                inputControl: "select",
                options: [
                    {id: "dark", name: "Dark"},
                    {id: "light", name: "Light"},
                    {id: "plain", name: "Plain"}
                ]
            }
        ];
    }
}
