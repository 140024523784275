import BaseModel from "../../../common/baseModel";

export default class ReturnBillModel extends BaseModel {

    name = "回单";
    api = "/shipment/returnBill";

    fields() {
        return [
            {
                key: "driver",
                filterable: {
                    type: "string",
                    key: "driverName"
                }
            },
            {
                key: "returnBillImage",
                inputControl: "idUpload",
                inputControlProps: {
                    type: "TableOCR"
                },
            },
            {
                key: "symbol",
            },
            {
                key: "status",
            },
            {
                key: "driver",
                filterable: {
                    type: "String",
                    key: "driverName|driverCarrier|driverMobile",
                    placeholder: "司机姓名/电话/承运商"
                }
            },
        ];
    }

}
