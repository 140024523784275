import routerUtils from "@/router/routerUtils";

import CustomerModel from "./model/CustomerModel";
import ConsignorModel from "./model/ConsignorModel";
import ConsignorBaseModel from "./model/ConsignorBaseModel";
// IMPORT_HOLDER

const routes = Array.from([
    CustomerModel,
    ConsignorModel,
    ConsignorBaseModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));



export default routes;