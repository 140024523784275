import BaseModel from "../../../common/baseModel";

export default class ChatRobotModel extends BaseModel {

    name = "群聊机器人";
    api = "/message/chatRobot";

    fields() {
        return [];
    }

}
