import Vue from "vue";
import CompanyUserModel from "@/modules/org/model/CompanyUserModel";

const companyModel = CompanyUserModel.getInstance()

export const hasPerm = Vue.directive('hasPerm', {
    inserted: function (el, binding) {
        const [action, resource] = binding.value.split(' ');
        const res = companyModel.isAuthorized(resource, action);
        if(!res) {
            el.remove()
        }
    },
})