import routerUtils from "@/router/routerUtils";

import ScheduleJobModel from "./model/ScheduleJobModel";
// IMPORT_HOLDER

const routes = Array.from([
    ScheduleJobModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));


export default routes;