import BaseModel from "../../../common/baseModel";

export default class WaybillDetailModel extends BaseModel {

    name = "运单明细";
    api = "/shipment/waybillDetail";

    fields() {
        return [
            {
                key: "startArea",
                detailEditable: true,
                inputControl: "region"
            },
            {
                key: "destination",
                detailEditable: true,
                inputControl: "region"
            },
            {
                key: "customer",
                detailEditable: true,
            },
            {
                key: "returnBillNo",
                detailEditable: true,
            },
            {
                key: "head",
                visibleInGrid: false
            },
            {
                key: "productSn",
                foreignKey: "symbol"
            },
            {
                key: "productSn.snStandard",
                label: "SN规格"
            }
    ];
}

}
