import BaseModel from "../../../common/baseModel";

export default class DriverModel extends BaseModel {

    name = "运力";
    api = "/carrier/driver";

    formGrouping = [
        {label: "基础信息（上传证件自动解析）", columns: ['carrier', 'name', 'idCard', 'mobile', 'address']},
        {label: "证件", columns: ['idCardImage',
                'driveLicenseImage', 'bankCardImage',
                'drivingLicenseImage', 'contractImage']},
        {label: "收款信息", columns: ['bank', 'bankUser', 'bankCardNo', 'bankBranch']},
        {label: "备注", columns: ["remark"]}
    ];

    columnWidth = 6;

    quickEdit = false;

    fields() {
        return [
            {
                key: "idCardImage",
                inputControl: "idUpload",
                inputControlProps: {
                    type: "IdCard"
                },
                columnWidth: 6,
            },
            {
                key: "bankCardImage",
                inputControl: "idUpload",
                inputControlProps: {
                    type: "BankCard"
                },
                columnWidth: 6,
            },
            {
                key: "driveLicenseImage",
                inputControl: "idUpload",
                inputControlProps: {
                    type: "DriveLicense"
                },
                columnWidth: 6,
            },
            {
                key: "driverUser",
                visibleInGrid: false,
                visibleInForm: false
            },
            {
                key: "driveLicenseLevel",
                inputControl: "select",
                placeholder: "请选择或添加",
                required: true,
                options: [
                    "C1", "C2", "B1", "B2", "A1", "A2"
                ],
                inputControlProps: {
                    allowCreate: true
                }
            },
            {
                key: "name",
            },
            {
                key: "remark",
                columnWidth: 24
            }
        ];
    }

}
