import BaseModel from "../../../common/baseModel";

export default class ConfigureModel extends BaseModel {

    name = "配置项";
    api = "/common/configure";

    fields() {
        return [

    ];
}

}
