import routerUtils from "@/router/routerUtils";

import NeedPayModel from "./model/NeedPayModel";
import PaymentBillModel from "./model/PaymentBillModel";
// IMPORT_HOLDER

const routes = Array.from([
    NeedPayModel,
    PaymentBillModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));


export default routes;