import BaseModel from "../../../common/baseModel";

export default class ApproveProcessInstanceModel extends BaseModel {

    name = "付款审批实例";
    api = "/common/approveProcessInstance";

    fields() {
        return [

    ];
}

}
