import routerUtils from "@/router/routerUtils";

import PageReportModel from "./model/PageReportModel";
import ScheduledReportModel from "./model/ScheduledReportModel";
// IMPORT_HOLDER

let routes = Array.from([
    PageReportModel,
    ScheduledReportModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));


routes = routes.concat([
    {
        path: '/report/pageReportView/:viewId',
        component: routerUtils.importPage('report/view/jmReportView'),
        meta: {
            title: '报表显示',
            key: "path"
        }
    },
]);

export default routes;