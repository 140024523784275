import routerUtils from "@/router/routerUtils";
import RoleModel from "./model/RoleModel";
import BranchOfficeModel from "./model/BranchOfficeModel";
import CompanyUserModel from "./model/CompanyUserModel";
import PermissionModel from "./model/PermissionModel";
// IMPORT_HOLDER

const routes = Array.from([
    RoleModel,
    BranchOfficeModel,
    CompanyUserModel,
    PermissionModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));


routes.push({
    path: '/org/role/assignPermission/:roleId',
    name: 'AssignRolePermission',
    component: routerUtils.importPage('org/view/roleAssignPermission'),
    meta: {
        title: '分配岗位权限'
    }
});

export default routes;