import BaseModel from "../../../common/baseModel";

export default class ScheduleJobModel extends BaseModel {

    name = "定时任务";
    api = "/schedule/scheduleJob";

    fields() {
        return [];
    }

}
