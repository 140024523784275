<template>
    <el-container class="el-container"
                  :class="`view-size-${$store.getters.viewSize} normal`"
    >
        <div class="small-size-menu-cover"
             v-if="!menuCollapsed"
             @click="() => collapse(true)"></div>
        <el-menu
            :collapse="menuCollapsed"
            background-color="#001529"
            text-color="hsla(0,0%,100%,.65)"
            active-text-color="#fff"
            :unique-opened="true"
            class="el-menu-container main-menu-container">
            <el-submenu v-for="(menu, index) in menus" :key="index" :index="menu.id.toString()">
                <template slot="title">
                    <!--                        <div @click="() => goNavigationPage(index)">-->
                    <!--                            <i :class="'el-icon-' + menu.icon + ' ' + menu.icon"></i>-->
                    <!--                            <span>{{ menu.name }}</span>-->
                    <!--                        </div>-->
                    <div>
                        <i :class="'el-icon-' + menu.icon + ' ' + menu.icon"></i>
                        <span>{{ menu.name }}</span>
                    </div>
                </template>
                <div v-for="son in menu.children" :key="son.id">
                    <el-menu-item @click="go(son)" :index="son.id.toString()" v-if="!son.children">
                        <i :class="'el-icon-' + son.icon + ' ' + son.icon"></i>
                        <span>{{son.name}}</span>
                    </el-menu-item>
                    <el-submenu :index="son.id.toString()" v-else>
                        <template slot="title">
                            <i :class="'el-icon-' + son.icon + ' ' + son.icon"></i>
                            <span>{{son.name}}</span>
                        </template>
                        <el-menu-item @click="go(gs)" :index="gs.id.toString()" v-for="gs in son.children" :key="gs.id">
                            <span>{{gs.name}}</span>
                        </el-menu-item>
                    </el-submenu>
                </div>
            </el-submenu>
            <!--            </div>-->
        </el-menu>

        <el-container id="main-content-wrap" :class="menuCollapsed ? 'collapse' : 'normal'">
            <el-header class="header-container">
                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-button type="text" class="collapse-btn" @click="() => collapse(!menuCollapsed)">
                            <i v-show="!menuCollapsed" class="el-icon-s-fold f-xl black"></i>
                            <i v-show="menuCollapsed" class="el-icon-s-unfold f-xl black"></i>
                        </el-button>
                        &nbsp;
                    </el-col>
                    <el-col :span="20" class="header-text-content">
                        <global-search />
                        <a class="avatar f-small hidden-sm-and-down" v-if="currentUser.company" @click="switchBoVisible = true">
                            {{currentUser.company.name}}
                            -
                            {{currentUser.branchOffice.name}}

                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </a>
                        <a class="hidden-md-and-up" v-if="currentUser.company" @click="switchBoVisible = true">
                            <i class="el-icon-sort"></i>
                        </a>
                        <el-divider class="hidden-sm-and-down ml-10" direction="vertical" v-if="currentUser.company"></el-divider>
                        <el-dropdown trigger="click" class="hidden-sm-and-down">
                            <span class="avatar f-small">
                                {{currentUser.name}}
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-button size="small" type="text" @click="showSetPasswordDialog">修改密码</el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button size="small" type="text" @click="reloadPage">重新载入</el-button>
                                </el-dropdown-item>
                                <el-dropdown-item divided>
                                    <el-popconfirm
                                        title="确定要退出登录吗？"
                                        @confirm="logout"
                                    >
                                        <div slot="reference">
                                            <el-button size="small" type="text">退出登录</el-button>
                                        </div>
                                    </el-popconfirm>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>

                        <span class="hidden-xs-only">
                            <el-divider direction="vertical" v-if="currentUser.company"></el-divider>
                            <a class="f-small" @click="showExportDialog">
                                <i class="el-icon-download el-icon--right"></i>
                            </a>
                        </span>
                    </el-col>
                </el-row>
            </el-header>
            <el-main class="main-container" v-if="loaded > 3">
                <router-view></router-view>
            </el-main>
        </el-container>

        <el-dialog
            title="切换分支机构"
            v-if="currentUser.company"
            :visible.sync="switchBoVisible"
            width="30%">
            <div>
                <span v-for="bo in currentUser.branchOfficeList || []" :key="bo.id" class="mr-10 mb-10">
                    <el-button type="primary"
                               class="mt-10"
                               size="small" v-if="currentUser.branchOffice.id === bo.id">
                        [当前] {{bo.name}}
                    </el-button>
                    <el-popconfirm
                        :title="`确定要切换到主企业 ${bo.name} 吗？`"
                        @confirm="switchBo(bo)"
                        class="mt-10"
                        v-else
                    >
                        <el-button slot="reference" size="small">
                            {{bo.name}}
                        </el-button>
                    </el-popconfirm>
                </span>
            </div>

            <div class="mt-10" v-if="currentUser.companyList.length > 1">
                <div style="height:20px;width:100%;"></div>
                <div class="f-large mt-10 mb-10">切换账号</div>
                <div v-for="com in currentUser.companyList || []" :key="com.id" class="mr-10 mb-10">
                    <el-button type="primary"
                               size="small" v-if="currentUser.company.id === com.id">
                        [当前] {{com.name}}
                    </el-button>
                    <el-popconfirm
                        :title="`确定要切换账号到 ${com.name} 吗？`"
                        @confirm="switchCompany(com)"
                        v-else
                    >
                        <el-button slot="reference" size="small">
                            {{com.name}}
                        </el-button>
                    </el-popconfirm>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="switchBoVisible = false" size="small">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog  :close-on-click-modal="false" :visible.sync="setPasswordDialogVisible" title="设置密码">
            <el-form size="small" label-width="80">
                <el-form-item v-if="!currentUser.passwordAlreadySet">您还没有设置密码，需要现在设置一个吗？</el-form-item>
                <el-form-item v-if="currentUser.passwordAlreadySet" label="旧密码">
                    <el-input style="width:200px" v-model="oldPassword" size="small"></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input style="width:200px" v-model="newPassword" size="small"></el-input>
                </el-form-item>
                <el-form-item label="" class="mt-10">
                    <el-button size="small" type="primary" @click="setPassword" :disabled="!newPassword || newPassword.length < 6">确定设置</el-button>
                    <el-button size="small" class="ml-10" @click="() => {setPasswordDialogVisible = false;}">暂时不用</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog  :close-on-click-modal="false" :visible.sync="exportDialogVisible" width="80%" v-if="exportDialogVisible">
            <base-table
                :default-actions-btn="['refresh']"
                :show-summary="false"
                operation-fixed="right"
                :model="exportModel">
                <template v-slot:operations="row">
                    <el-button type="text"
                               @click="doExportDownload(row.row)"
                               size="small">下载</el-button>
                </template>
            </base-table>
        </el-dialog>

        <iframe ref="frameBridge" id="frameBridge" name="frameBridge" style="width:0;height:0;position: absolute;display:block;border:none;right:0;bottom:0;z-index: -1;overflow:hidden;"></iframe>
        <form ref="frameBridgeForm" style="width:0;height:0;overflow:hidden;" id="frameBridgeForm" name="frameBridgeForm"></form>

    </el-container>

</template>

<script>

import DataStructure from "../../common/dataStructure";
import ComConfigure from "../../common/configure";
import ExportTaskModel from "./model/ExportTaskModel";
import {axiosDownload} from "@/common/utils";
import GlobalSearch from "./components/globalSearch";

export default {
    name: "MainContainer",
    components: {GlobalSearch},
    data() {
        return {
            menus: [],
            loaded: 0,
            currentUser: this.globalData.user,

            switchBoVisible: false,

            oldPassword: null,
            newPassword: null,
            setPasswordDialogVisible: false,

            exportDialogVisible: false,
            exportModel: ExportTaskModel,

            ds: DataStructure.getInstance(),
            menuCollapsed: false
        };
    },
    mounted() {

        if(this.$store.getters.isSmallView) {
            this.menuCollapsed = true;
        } else {
            this.menuCollapsed = localStorage.getItem("mainMenuCollapsed") === "true";
        }
        // console.log(this.menuCollapsed)

        let me = this;
        this.axios.get("/common/userMenu/getTree").then((menus) => {
            me.globalData.globalMenus = me.menus = menus;
        });

        this.ds.load(false, () => {
            me.loaded += 1;
        });

        ComConfigure.getInstance().load(false, () => {
            me.loaded += 1;
        });

        this.axios.get("/common/frontEndLayout").then(res => {
            me.loaded += 1;
            this.globalData.layouts = res;
        });

        this.axios.get("/common/itemTag").then(res => {
            const allTagsMap = {}
            res.items.forEach(item => {
                allTagsMap[item.tagType] = allTagsMap[item.tagType] || {}
                allTagsMap[item.tagType][item.name] = item;
            });
            this.globalData.allTagsMap = allTagsMap
        });

        this.axios.get("/org/branchOffice", {
            params: {
                pageSize: 9999
            }
        }).then(res => {
            me.loaded += 1;
            this.ds.branchOfficeList = res.items;
            this.ds.branchOfficeMap = this._.keyBy(res.items, "id");
        });

        if(this.currentUser && !this.currentUser.passwordAlreadySet) {
            this.setPasswordDialogVisible = true;
        }

        setTimeout(function() {
            me.globalData.frameBridge = me.$refs.frameBridge;
            me.globalData.frameBridgeForm = me.$refs.frameBridgeForm;
        }, 1000);

        window.onresize = function() {
            me.handleWindowResize();
        };

        me.handleWindowResize();
    },
    methods: {
        goNavigationPage(index) {
            this.$router.push("/navigation?index=" + index);
        },
        handleWindowResize() {
            this.$store.commit("updateScreenSize", document.documentElement);
            if(this.$store.getters.viewSize === 'small') {
                this.$store.commit("collapseMenu", true);
            }
        },
        collapse(status) {
            localStorage.setItem("mainMenuCollapsed", status);
            this.menuCollapsed = !this.menuCollapsed;
            // this.$store.commit("collapseMenu", status);
        },

        logout() {
            this.axios.delete("/org/session", {
                params: {
                    mobile: this.currentUser.mobile,
                    loginSource: this.globalData.loginSource,
                    roleType: this.currentUser.roleType
                }
            }).then(() => {
                localStorage.clear();
                location.hash = "#/";
                location.reload();
            })
        },

        go(menu) {
            if(!menu.link) {
                return;
            }

            this.$router.push(menu.link);
        },

        switchBo(bo) {
            this.axios.put("/org/session/switchBranchOffice/" + bo.id).then(() => {
                location.reload();
            });
        },

        switchCompany(com) {
            this.axios.put("/org/session/switchCompany/" + com.id).then(() => {
                location.reload();
            });
        },

        showSetPasswordDialog() {
            this.setPasswordDialogVisible = true;
        },
        setPassword() {
            this.axios.put("/org/session/setPassword", {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword
            }).then(() => {
                this.$message.success("设置成功");
                this.setPasswordDialogVisible = false;
            });
        },

        showExportDialog() {
            this.exportDialogVisible = true;
        },
        doExportDownload(row) {
            let url = "/common/exportTask/download/" + row.id;
            axiosDownload(this.axios, url, row.name);
        },
        reloadPage() {
            window.location.reload();
        }
    }
}
</script>

<style scoped lang="scss">
.el-container {
    height: 100%;
}

.header-container {
    text-align: right;
    font-size: 12px;
    border-bottom: 1px solid #e6e6e6;
}

.main-container {
    padding: 0;
}

.main-tabs-container {
    margin: 5px;background: #fff;
    padding: 10px;
}
//.el-menu {
//    border-right: none !important;
//}
//.el-menu-container > .el-menu-item:first-child {
//    margin-top: 40px;
//}
//.el-menu-item.is-active {
//    background: #409EFF !important;
//}

.header-text-content {
    height: 24px;
    line-height: 24px;
    margin-top: 18px;
}


.collapse-btn {
    position: absolute;
    left: 0;
    top: 10px;
    z-index: 10;
}

//.main-menu-container {
//    height: 100%;
//    overflow: scroll;
//    transition: all linear .3s;
//    &::-webkit-scrollbar {
//        display: none;
//    }
//}
#main-content-wrap {
    //position: absolute;
    //left: 0;
    //top:0;
    //width:100%;
    height:100%;
    overflow: hidden;
}

.small-size-menu-cover {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    display: none;
    background: rgba(0,0,0, .3);
}

.view-size-normal {

    .el-menu-container:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }

}

.view-size-small {
    .main-menu-container {
        position: absolute;
        left:0;top:0;
        display: none;
        height: 100%;
        //width: 200px;
    }

    ::v-deep .el-menu {
        width: 200px;
        z-index: 9999;
        left: 0;
    }

    ::v-deep .el-menu--collapse {
        width: 0;
        left: -60px;
    }

    .header-container {
        background-color: #409EFF;
        color: #fff;
        .black {
            color: #fff;
        }
    }

    .el-menu {
        //width: 200px;
        //z-index: 99;

        //&.el-menu--collapse {
        //    min-width: 0;
        //}
    }

    &.normal .small-size-menu-cover {
        display: block;
    }

    &.normal {
        .main-menu-container {
            display: block;
        }

        .collapse-btn {
            //margin-left: 200px;
        }
    }
}
</style>
<style lang="scss">
//.el-menu--vertical .el-menu{
//    display: none;
//}
//ul.el-menu {
//    .el-submenu__icon-arrow {
//        display: none;
//    }
//}
</style>
