import BaseModel from "../../../common/baseModel";

export default class BlackListModel extends BaseModel {

  name = "司机黑名单";
  api = "/carrier/blackList";

  fields() {
    return [
      {
        key: "remark",
        visibleInGrid: false,
        inputControl: "textarea",
      }
    ];
  }

}
