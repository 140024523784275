<template>
    <el-dialog  :close-on-click-modal="false"
                append-to-body
                width="80%"
                :title="pageTitle"
                v-loading="loading"
                v-if="modalVisible && modelInstance" :visible.sync="modalVisible">
        <div v-if="detailData">
            <slot name="detail-title" :row="detailData"></slot>
            <div v-for="(group, index) in modelInstance.formGrouping" :key="index">
                <el-descriptions class="mt-10"
                                 :labelStyle="{width: '100px', textAlign: 'right'}"
                                 :column="$store.getters.isSmallView ? 1 : modelInstance.detailColumnPerRow || 4"
                                 :title="group.label" size="mini" border>
                    <el-descriptions-item :label="getColumnOptions(column).label || getColumnOptions(column).key"
                                          :span="getColumnOptions(column).detailSpan || 1"
                                          v-for="(column, i) in group.columns.filter(c => hiddenColumns.indexOf(c) < 0)" :key="i">
                        <slot :name="`custom-detail_${column}`" :item="detailData[column]" :row="detailData">
                            <field-display :api="modelInstance.getApi()"
                                           @needUpdate="loadData"
                                           :column="getColumnOptions(column)"
                                           :row="detailData"></field-display>
                        </slot>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <slot name="extra-info" :row="detailData"></slot>
        </div>
    </el-dialog>
</template>

<script>
import FieldDisplay from "@/common/components/FieldDisplay.vue";
import {getForeignDisplay} from "@/common/utils";

export default {
    name: "ItemDeailModal",
    components: {FieldDisplay},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        },
        raw: {
            type: Object,
        },
        modelInstance: {
            type: Object,
            required: true
        }
    },
    computed: {
        pageTitle() {
            if(this.title) {
                return this.title;
            }

            const title = []
            if(this.raw.name) {
                title.push(this.raw.name)
            }
            if(this.raw.symbol) {
                title.push(this.raw.symbol)
            }
            if(this.modelInstance.displayField) {
                title.push(this.getForeignDisplay(this.raw, this.modelInstance.displayField))
            }
            return `${this.modelInstance.name}详情 - [${title.join(" - ")}]`
        },
        modalVisible: {
            get() {
                return this.value;
            },
            set() {
                this.$emit("input", false);
            }
        }
    },
    data() {
        return {
            detailData: null,
            hiddenColumns: Array.from(
                Object.keys(this.modelInstance.fieldsMap).filter(k => {
                    return this.modelInstance.fieldsMap[k].visibleInDetail === false;
                })
            ) || [],
            loading: false,
            getForeignDisplay: getForeignDisplay
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        getColumnOptions(column) {
            return this.modelInstance.fieldsMap[column] || {}
        },
        loadData() {
            this.loading = true;
            this.detailData = null
            this.axios.get(this.modelInstance.getApi() + "/" + this.raw.id).then(res => {
                this.detailData = res
                this.loading = false
            }).catch(err => {
                this.$message.error("加载详情失败： " + err.methods);
                this.loading = false
            })
        }
    }
}
</script>