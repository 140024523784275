import BaseModel from "../../../common/baseModel";

export default class InventoryModel extends BaseModel {

    name = "库存清单";
    api = "/storage/inventory";

    fields() {
        return [

    ];
}

}
