import BaseModel from "../../../common/baseModel";

export default class ExportTaskModel extends BaseModel {

    name = "导出任务";
    api = "/common/exportTask";

    fields() {
        return [
        ];
    }

}
