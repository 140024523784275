import routerUtils from "@/router/routerUtils";

import ShipmentMileageModel from "./model/ShipmentMileageModel";
// IMPORT_HOLDER

const routes = Array.from([
    ShipmentMileageModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));

routes.push(
    {
        path: '/externalApiSync/syncFromTms/receiptApply',
        name: 'receiptApply',
        component: routerUtils.importPage('settlement/view/receiptApply'),
        meta: {
            title: "退账申请"
        }
    },
    {
        path: '/settlement/returnBillPool',
        name: 'returnBillPool',
        component: routerUtils.importPage('settlement/view/returnBillPool'),
        meta: {
            title: "回单池"
        }
    },
)

export default routes;
