import BaseModel from "../../../common/baseModel";

export default class WaybillProfitItemModel extends BaseModel {

    name = "利润测算";
    api = "/shipment/waybillProfitItem";

    fields() {
        return [
            {
                key: "policy",
                inputControl: "combo",
                defaultQueryParams: {
                    // policyType: "@TruckWaybillProfit,CommonWaybillProfit"
                    policyType: "TruckWaybillProfit"
                }
            },
            {
                key: "waybill",
                getDisplay(raw) {
                    return raw ? raw.symbol : raw;
                }
            },

        ];
    }

}
