import routerUtils from "@/router/routerUtils";

import ProductModel from "./model/ProductModel";
// IMPORT_HOLDER

const routes = Array.from([
    ProductModel
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));



export default routes;