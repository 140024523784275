import axios from 'axios'
import _ from "lodash"
import $moment from "moment"

class DataStructure {

    dataStructure;

    dsMap = {};

    instance;

    enumMap = {};

    preLoadedForeign;

    alreadyPreLoadedForeignApi;

    constructor() {
        this.dataStructure = {};
        this.instance = null;
        this.preLoadedForeign = {};
        this.alreadyPreLoadedForeignApi = [];
    }

    static getInstance() {
        if(!this.instance) {
            this.instance = new DataStructure();
        }
        return this.instance;
    }

    load(force, callback) {
        if(!Object.keys(this.dataStructure).length || true === force) {
            axios.get("/common/configure/company/dataStructure").then(res => {
                DataStructure.getInstance().dataStructure = res;

                Object.keys(res).forEach(k => {
                    let map = {};
                    Object.keys(res[k]).forEach(item => {
                        map[res[k][item].key] = res[k][item];
                    });
                    this.dsMap[k] = map;
                });

                callback(res);
            });
        }
    }

    get(api) {
        return this.dataStructure[api] || [];
    }

    getMap(api) {
        return this.dsMap[api] || {};
    }

    setAlreadyLoadedApi(api) {
        this.alreadyPreLoadedForeignApi.push(api);
    }

    isAlreadyLoadedApi(api) {
        return this.alreadyPreLoadedForeignApi.indexOf(api) >= 0;
    }

    setPreLoadedForeign(api, data) {
        this.preLoadedForeign[api] = data;
    }

    getPreLoadedForeign(api) {
        return this.preLoadedForeign[api];
    }

    getEnumDisplay(api, field, value) {
        let me = DataStructure.getInstance(),
            enumName = api + "_" + field;
        const config = me.getMap(api)[field];

        if(!config.staticStore || config.staticStore.length < 1) {
            return value;
        }

        if(!me.enumMap[enumName] || Object.keys(me.enumMap[enumName]).length === 0) {
            me.enumMap[enumName] = {};
            config.staticStore.forEach(item => {
                me.enumMap[enumName][item.id] = item.name;
            });
        }

        return me.enumMap[enumName][value]
    }

    getDisplay(column, value, row) {
        if(typeof column.getDisplay === "function") {
            return column.getDisplay(value, row);
        }

        if(this._.isString(value)) {
            return value;
        }

        if(this._.isObject(value)) {
            return column.labelField ? (value[column.labelField] || value.name || value.symbol) : value.name || value.symbol
        }

        console.log(column.key, value)

        return value;
    }

    getFormSubmitValue(data, model) {
        let result = {},
            raw = data || {};

        Object.keys(raw).forEach(field => {
            const columnConfigs = model.getFields(true)[field] || {};
            let value = raw[field];

            switch(columnConfigs.inputControl) {
                case "region":
                    if(_.isArray(value)) {
                        columnConfigs.inputControlProps = columnConfigs.inputControlProps || {};
                        if(columnConfigs.inputControlProps.multiple) {
                            value = Array.from(value, item => {
                                return item.join(" ");
                            }).join(",");
                        } else {
                            value = value.join(" ")
                        }
                    }
                    break;
                case "date":
                    value = $moment(value).format("yyyy-MM-DD HH:mm:ss");
                    break;
                case "select":
                    if(columnConfigs.type === 'ENUM') {
                        value = value ? (value.id === undefined ? value : value.id) : null;
                    }
                    if(value === "") {
                        value = null
                    }
                    break;
                case "combo":
                    if(value === "") {
                        value = null
                    }
                    break;
            }

            if(typeof columnConfigs.getSubmitValue === 'function') {
                result[field] = columnConfigs.getSubmitValue(value, raw)
            } else {
                result[field] = value;
            }
        });


        return result;
    }

    setMap(api, map) {
        this.dsMap[api] = map;
    }
}

export default DataStructure;
