import BaseModel from "../../../common/baseModel";

export default class TruckIncomeItemModel extends BaseModel {

    name = "商品车收入";
    api = "/truck/truckIncomeItem";

    fields() {
        return [
            {
                key: "policy",
                inputControl: "combo",
                defaultQueryParams: {
                    policyType: "TruckIncome"
                }
            },
            {
                key: "truck",
                getDisplay(raw) {
                    return raw ? raw.symbol : raw;
                },
                filterable: {
                    key: "symbol",
                    fuzzy: true,
                    type: "string"
                }
            },
            {
                key: "basisList",
                filterable: {
                    key: "calculateBasis",
                    fuzzy: true,
                    type: "string"
                }
            }
        ];
    }

}
