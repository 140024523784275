import BaseModel from "../../../common/baseModel";

export default class ConsignorModel extends BaseModel {

    name = "货主";
    api = "/customer/consignor";

    fields() {
        return [
            {
                key: "area",
                inputControl: "region"
            },
            {
                key: "snStandardPolicy",
                inputControl: "combo",
                defaultQueryParams: {
                    policyType: "SnStandard"
                }
            }
        ];
    }

}
