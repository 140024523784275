export default {

    getRules(definedColumns) {
        let rules = {};

        definedColumns.forEach(column => {
            if(column.rules !== undefined) {
                return;
            }

            let columnRules = [];
            if(column.required === true) {
                let rule = {
                    required: true,
                    trigger: "change",
                    message: `请输入 ${column.label}`
                };

                // if(column.type === "DATE") {
                //     rule.type = "date";
                // }
                columnRules.push(rule);
            }

            if(column.length) {
                columnRules.push({
                    maxLength: column.length,
                    trigger: "change",
                    message: `${column.label} 长度不能超过 ${column.length}`
                });
            }

            if(columnRules.length) {
                rules[column.key] = columnRules;
            }
        });

        return rules;

    }

};