import routerUtils from "@/router/routerUtils";

import ArrivalNoticeModel from "./model/ArrivalNoticeModel";
import ArrivalNoticeDetailModel from "./model/ArrivalNoticeDetailModel";
// IMPORT_HOLDER

let routes = Array.from([
    ArrivalNoticeModel,
    ArrivalNoticeDetailModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));

routes = routes.concat([
    {
        path: '/stockIn/arrivalNotice/add',
        name: 'addAsn',
        component: routerUtils.importPage('stockIn/view/arrivalNoticeEdit'),
        meta: {
            title: "新增预计到货"
        }
    },
    {
        path: '/stockIn/arrivalNotice/edit/:billId',
        name: 'editAsn',
        component: routerUtils.importPage('stockIn/view/arrivalNoticeEdit'),
        meta: {
            title: "修改预计到货"
        }
    },
    {
        path: '/stockIn/receiveBySN',
        name: 'receiveBySN',
        component: routerUtils.importPage('stockIn/view/receiveBySN'),
        meta: {
            title: "按SN收货"
        }
    }
]);


export default routes;