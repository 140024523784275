import routerUtils from "@/router/routerUtils";

import CustomerOrderModel from "./model/CustomerOrderModel";
import CustomerOrderDetailModel from "./model/CustomerOrderDetailModel";
// IMPORT_HOLDER

let routes = Array.from([
    CustomerOrderModel,
    CustomerOrderDetailModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));

routes = routes.concat([

]);

export default routes;
