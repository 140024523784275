<template>
  <el-input class="mr-10 global-search"
            @keydown.enter.native="handleGlobalSearch"
            @focus="handleGlobalSearchFocus"
            v-model="globalSearchKeyword" size="small"
            :placeholder="availableSearchCategory[currentCategory].placeholder">
    <template v-slot:prepend>
      <el-dropdown @command="handleChangeCategory" trigger="click" size="small">
                <span class="el-dropdown-link f-small">
                {{availableSearchCategory[currentCategory].name}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="k"
                            v-for="(v, k) in availableSearchCategory"
                            :key="k">{{v.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
  </el-input>
</template>

<script>
export default {
  name: "globalSearch",
  data() {
    return {
      currentCategory: "Truck",
      availableSearchCategory: {
        Truck: {
          name: "商品车",
          placeholder: "VIN/回单号/客户/目的地",
          handler(me, keyword) {
            me.$router.push({
              path: "/mobile/business/vinQuery",
              query: {
                keyword: keyword
              }
            })
          }
        },
        TruckDriver: {
          name: "车查单",
          placeholder: "VIN（长号短号都可以）",
          handler(me, keyword) {
            me.$router.push({
              path: "/mobile/business/queryDriverByVin",
              query: {
                keyword: keyword
              }
            });
          }
        },
        Driver: {
          name: "驾驶员",
          placeholder: "姓名/电话/身份证号码",
          handler(me, keyword) {
            me.$router.push({
              path: "/mobile/business/driverQuery",
              query: {
                keyword: keyword
              }
            })
          }
        }
      },
      globalSearchKeyword: ""
    }
  },
  methods: {
    handleChangeCategory(category) {
      this.currentCategory = category
    },
    handleGlobalSearchFocus(e) {
      e.target.select()
    },
    handleGlobalSearch() {
      const keyword = this.globalSearchKeyword.trim()
      if(keyword.length < 2) {
        return
      }
      
      const category = this.availableSearchCategory[this.currentCategory]
      if(typeof category.handler === 'function') {
        category.handler(this, keyword)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.global-search {
  width: auto;
  
  ::v-deep .el-input-group__prepend {
    padding: 0 8px;
  }
}
.view-size-small .global-search {
  width:80%
}
</style>
