import BaseModel from "../../../common/baseModel";

export default class WaybillPoolModel extends BaseModel {

    name = "运单池";
    api = "/shipment/waybillPool";

    fields() {
        return [];
    }

}
