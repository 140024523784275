import BaseModel from "../../../common/baseModel";
import app from "../../../main";

export default class CompanyUserModel extends BaseModel {

    name = "公司员工";
    api = "/org/companyUser";

    authorities = [];
    authoritiesMap = {};

    fields() {
        return [
            {
                key: "roleType",
                visibleInGrid: false,
                editable: false
            },
            {
                key: "authorities",
                visibleInGrid: false
            },
            {
                key: "app",
                visibleInGrid: false
            },
            {
                key: "cloudOrganization",
                visibleInGrid: false
            },
            {
                key: "company",
                visibleInForm: false
            },
            {
                key: "companyList",
                visibleInForm: false,
                visibleInGrid: false
            },
            {
                key: "branchOffice",
                preload: true
            },
            {
                key: "roleList",
                width: 150,
                visibleInForm: false
            },
            {
                key: "branchOfficeList",
                width: 150,
                visibleInForm: false
            },
        ];
    }

    isAuthorized(resource, action) {
        app.globalData.user.authorities = app.globalData.user.authorities || [];
        if(!this.authorities.length) {
            app.globalData.user.authorities.forEach(item => {
                let symbol = item.symbol.toUpperCase();
                this.authorities.push(symbol);
                this.authoritiesMap[symbol] = item;
            });
        }

        let rawPerm = (action + " " + resource).toUpperCase(),
            managePerm = action === 'GET' ? ("管理 " + resource).toUpperCase() : null;
        return this.authorities.indexOf(rawPerm) >= 0 || this.authorities.indexOf(managePerm) >= 0;
    }

}
