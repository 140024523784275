import axios from 'axios'

class ComConfigure {

    configureMap;

    instance;
    constructor() {
        this.configureMap = {};
        this.instance = null;
    }

    static getInstance() {

        if(!this.instance) {
            this.instance = new ComConfigure();
        }
        return this.instance;
    }

    load(force, callback) {
        let me = this;
        if(!Object.keys(this.configureMap).length || true === force) {
            axios.get("/common/configure").then(res => {
                res.items.forEach(item => {
                    me.configureMap[item.configureItem] = item;
                });

                callback(res);
            });
        }
    }

    get(name) {
        return this.configureMap[name] ? this.configureMap[name].value : null;
    }

    getList(name) {
        let raw = this.get(name);
        if(!raw) {
            return [];
        }

        return raw.split(",");
    }

    getStaticStore(name) {
        let item = this.get(name),
            options = [];
        if(!item) {
            return [];
        }

        item.split(",").forEach(val => {
            options.push(val);
            // options.push({
            //     id: val,
            //     name: val
            // });
        });

        return options;
    }
}

export default ComConfigure;