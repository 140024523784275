import Vue from 'vue';
import VueAxios from 'vue-axios'
import axios from 'axios'
import { MessageBox } from 'element-ui';

Vue.use(VueAxios, axios)

// let loading;
// let loadingNum=0;
// function startLoading() {
//     if(loadingNum === 0){
//         loading = Loading.service({
//             lock: true,
//             target: '#main-content-wrap',
//             text: '加载中...',
//             customClass: "global-loading",
//             background:'rgba(255,255,255,0.8)',
//         })
//     }
//     //请求数量加1
//     loadingNum++;
// }
// function endLoading() {
//     //请求数量减1
//     loadingNum--
//     if (loadingNum <= 0) {
//         loadingNum = 0;
//         loading.close()
//     }
// }

function initAxios(server, loginSource) {

    axios.defaults.baseURL = server;

    const token = localStorage.getItem("token");
    // const token = "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0ZWFtc3dpZnQiLCJzdWIiOiJNYW5hZ2VyfDEzMzI1MjUxNjI2IiwiaWF0IjoxNjMwOTk3ODgzLCJleHAiOjE2MzEwMDUwODN9.dKf9Bugnm5e--ce-I54AJDYonIY2ftJh5i4ZgM-_-Ac";
    if(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    }

    axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['Login-Source'] = loginSource;
    // axios.defaults.headers.common['Api-Version'] = '0.1';
    // axios.defaults.headers.common['Call-Source'] = 'WEB';

    Vue.prototype.requesting = 0;
    axios.interceptors.request.use(function (config) {
        // 在发送请求之前做些什么
        // if(!config.ignoreLoading) {
        //     startLoading();
        // }

        return config;
    }, function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (raw) {
        // endLoading();
        const response = raw.data;

        if(!response || response.status === undefined) {
            console.log('return')
            return raw;
        }

        if(response.status === 2001) {
            return response.data;
        }

        if (response.status !== 200 && response.message && response.code !== 2001) {
            MessageBox.alert(response.errors || response.error || response.message,{
                title: response.message + '.',
                message: response.errors || response.error || response.message
            });
            return Promise.reject(raw);
        } else if (response.status === 200 && typeof response.data !== "object" && response.message !== "成功") {
            MessageBox.alert(response.message || response.data || "操作成功", {
                title: "提示",
                message: response.message || response.data || "操作成功"
            });
        }
        if (response.success === false) {
            if(response.status !== 2001) {
                MessageBox.alert(response.message || "服务器错误", {
                    title: response.errors,
                    message: response.message || "服务器错误"
                });
            }
            return Promise.reject(raw);
        }

        return response.data;
    }, function (error) {
        // endLoading();

        if(!error.response || !error.response.data) {
            return Promise.reject(error);
        }

        // 不处理
        if(error.config.customProcessError) {
            return Promise.reject(error);
        }

        // 对响应错误做点什么
        if(error.response.data.code === 2001) {
            return Promise.reject(error.response, error);
        }

        MessageBox.alert(error.response.data.errors || error, {
            title: error.response.data.message,
            message: error.response.data.errors || error
        });

        return Promise.reject(error.response, error);
    });
}

export default { initAxios, axios };
