import BaseModel from "../../../common/baseModel";

export default class PolicyModel extends BaseModel {

    name = "策略";
    api = "/policy/policy";

    quickEdit = false;

    fields() {
        return [
            {
                key: "script",
                visibleInGrid: false,
                inputControl: "textarea",
                inputControlProps: {

                },
            }
        ];
    }

}
