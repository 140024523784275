import Vue from "vue";
import VueRouter from 'vue-router'
// RouterTab 内置路由
import { RouterTabRoutes } from 'vue-router-tab'

Vue.use(VueRouter)

// 引入布局框架组件
import TabsFrame from "../modules/common/TabsFrame";
import routerUtils from "./routerUtils";

// 导入模块路由
import commonRoutes from "../modules/common/routes";
import manageRoutes from "../modules/manage/routes";
import orgRoutes from "../modules/org/routes"
import productRoutes from "../modules/product/routes"
import storageRoutes from "../modules/storage/routes"
import customerRoutes from "../modules/customer/routes"
import stockInRoutes from "../modules/stockIn/routes"
import truckRoutes from "../modules/truck/routes"
import shipmentRoutes from "../modules/shipment/routes"
import carrierRoutes from "../modules/carrier/routes"
import paymentRoutes from "../modules/payment/routes"
import reportRoutes from "../modules/report/routes"
import policyRoutes from "../modules/policy/routes"
import settlementRoutes from "../modules/settlement/routes"
import orderRoutes from "../modules/order/routes"
import scheduleJobRoutes from "../modules/schedule/routes"
import mobileRoutes from "../mobile/routes"
import messageRoutes from "../modules/message/routes"

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

function initRouter() {
    return new VueRouter({
        routes: [
            {
                path: '/',
                // 父路由组件内必须包含 <router-tab>
                component: TabsFrame,
                // 子路由里配置需要通过页签打开的页面路由
                children: [
                    // 引入 RouterTab 内置路由以支持 Iframe 页签
                    ...RouterTabRoutes,
                    ...commonRoutes,
                    ...manageRoutes,
                    ...orgRoutes,
                    ...productRoutes,
                    ...storageRoutes,
                    ...customerRoutes,
                    ...stockInRoutes,
                    ...truckRoutes,
                    ...shipmentRoutes,
                    ...carrierRoutes,
                    ...paymentRoutes,
                    ...policyRoutes,
                    ...settlementRoutes,
                    ...reportRoutes,
                    ...orderRoutes,
                    ...scheduleJobRoutes,
                    ...mobileRoutes,
                    ...messageRoutes,
                    //
                    // {
                    //     path: '/page/:id',
                    //     component: routerUtils.importPage('Page'),
                    //     meta: {
                    //         title: '页面', // 页签标题
                    //         icon: 'icon-user', // 页签图标，可选
                    //         tabClass: 'custom-tab', // 自定义页签 class，可选
                    //         tips: '这是一个页面', // 页签提示，可选，如未设置则跟 title 一致
                    //         key: 'path', // 路由打开页签规则，可选
                    //     }
                    // },
                    {
                        path: '/404',
                        component: routerUtils.importPage('NotFound'),
                        meta: {
                            title: '找不到页面',
                            icon: 'icon-page'
                        }
                    }
                ]
            },
            {
                // 其他路由 404
                path: '*',
                redirect: '/404'
            }
        ]
    });
}


export { initRouter }
