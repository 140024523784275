import routerUtils from "@/router/routerUtils";

import CarrierModel from "./model/CarrierModel";
import DriverModel from "./model/DriverModel";
import ArtificialDriverModel from "./model/ArtificialDriverModel";
import DeliveryTruckModel from "./model/DeliveryTruckModel";
import BlackListModel from "./model/BlackListModel";
import DriverRegisterModel from "./model/DriverRegisterModel";
// IMPORT_HOLDER

let routes = Array.from([
    CarrierModel,
    ArtificialDriverModel,
    DeliveryTruckModel,
    DriverModel,
    BlackListModel,
    DriverRegisterModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));

routes = routes.concat([
    {
        path: "/carrier/artificialDriver/add",
        component: routerUtils.importPage('carrier/view/artificialDriverEdit'),
        meta: {
            title: "新增驾运司机"
        }
    },
    {
        path: "/carrier/artificialDriver/edit/:id",
        component: routerUtils.importPage('carrier/view/artificialDriverEdit'),
        meta: {
            title: "修改驾运司机"
        }
    },
    {
        path: "/carrier/deliveryTruck/add",
        component: routerUtils.importPage('carrier/view/deliveryTruckEdit'),
        meta: {
            title: "新增卡车运力"
        }
    },
    {
        path: "/carrier/deliveryTruck/edit/:id",
        component: routerUtils.importPage('carrier/view/deliveryTruckEdit'),
        meta: {
            title: "修改卡车运力"
        }
    },
    {
        path: "/carrier/driverRegister/add",
        component: routerUtils.importPage('carrier/view/driverRegisterEdit'),
        meta: {
            title: "运力注册"
        }
    },
    {
        path: "/carrier/driverRegister/edit/:id",
        component: routerUtils.importPage('carrier/view/driverRegisterEdit'),
        meta: {
            title: "运力注册"
        }
    },
]);


export default routes;