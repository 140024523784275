<template>
    <div id="app">
        <div v-if="loginStatus !== null" style="height: 100%">
            <main-container v-if="loginStatus === true"></main-container>
            <landing :roleType="roleType" v-else></landing>
        </div>
    </div>
</template>

<script>

import Landing from "./modules/common/Landing";
import MainContainer from "./modules/common/MainContainer";
import {getURLParams} from "./common/utils";
import DataStructure from "./common/dataStructure";
import "./assets/iconfont/iconfont"

export default {
    name: 'app',
    components: {
        Landing,
        MainContainer
    },
    data() {
        return {
            loginStatus: null,
            roleType: getURLParams().role === "Manager" ? "Manager" : "Company",

            firstLoading: true,
            dsInstance: DataStructure.getInstance(),

        };
    },
    mounted: function () {
        let me = this;

        const loading = this.$loading({
            lock: true
        });

        this.axios.get("/org/session/me", {
            customProcessError: this.firstLoading
        }).then((user) => {
            me.loginStatus = !!(user && user.id);
            me.globalData.user = user;
        }, () => {
            me.loginStatus = false;
        }).then(() => {
            this.firstLoading = false;
            loading.close();
        });
    },
}
</script>

<style lang="scss">
@import "assets/common.scss";
@import "assets/bill.scss";
@import "assets/overrides.scss";
@import "~element-ui/lib/theme-chalk/display.css";
@import "assets/iconfont/iconfont.css";

</style>

