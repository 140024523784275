import {axiosDownload} from "../utils";

export default {
    data() {
        return {
            importDialogVisible: false,
            importing: false,
            importAddress: "/common/importTask/import"
        };
    },
    methods: {
        showImportDialog() {
            this.importDialogVisible = true;
        },
        hideImportDialog() {
            this.importDialogVisible = false;
        },
        beforeImport(file){
            this.importing = true;

            let formData = new FormData();
            formData.set("api", this.modelInstance.getApi());
            formData.set("file", file)

            this.axios.post("/common/importTask", formData).then((res) => {
                let msg = `成功导入 ${res.successNum} 条数据。`;
                if(res.failureLines && res.failureLines.length > 0) {
                    msg += `但第 ${res.failureLines.join("、")} 行导入失败（不含表头）。`;
                }
                this.$alert(msg);
                this.hideImportDialog();
                this.loadData();
                this.importing = false;
            }, () => {
                this.importing = false;
            });
            return false;
        },

        handleUploadSuccess() {
            this.loading = false;
            this.loadData();
        },

        downloadImportTemplate(api) {
            axiosDownload(this.axios, "/common/importTask?api=" + api, "导入模板_" + this.modelInstance.name)
        }

    }
};