import BaseModel from "../../../common/baseModel";

export default class PageReportModel extends BaseModel {

    name = "页面报表";
    api = "/report/pageReport";

    fields() {
        return [];
    }

}
