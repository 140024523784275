import Vue from 'vue'
import Vuex from "vuex";

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        screenWidth:document.documentElement.clientWidth, //屏幕宽度
        screenHeight:document.documentElement.clientHeight, //屏幕高度

        collapseMenu: localStorage.getItem("mainMenuCollapsed") !== "false"
    },

    mutations: {
        updateScreenSize (state, el) {
            // 变更状态
            state.screenHeight = el.clientHeight;
            state.screenWidth = el.clientWidth;
        },
        collapseMenu(state, collapse) {
            state.collapseMenu = collapse;
        }
    },

    getters: {
        screenSize(state) {
            return {
                width: state.screenWidth,
                height: state.screenHeight
            };
        },
        viewSize(state) {
            return state.screenWidth <= 760 ? "small" : "normal";
        },
        isCollapse(state) {
            return state.collapseMenu;
        },
        isSmallView(state) {
            return state.screenWidth <= 760;
        }
    },

    actions: {
    },

    modules: {
    }

});
