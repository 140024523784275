import routerUtils from "@/router/routerUtils";

import ReturnBillModel from "./model/ReturnBillModel";
import WaybillModel from "./model/WaybillModel";
import CommonWaybillModel from "./model/CommonWaybillModel";
import WaybillDetailModel from "./model/WaybillDetailModel";
import CommonWaybillDetailModel from "./model/CommonWaybillDetailModel";
import AccidentModel from "./model/AccidentModel";
import AccidentDocumentModel from "./model/AccidentDocumentModel";
import AccidentProcessModel from "./model/AccidentProcessModel";
import WaybillProfitItemModel from "./model/WaybillProfitItemModel";
import WaybillPoolModel from "./model/WaybillPoolModel";
// IMPORT_HOLDER

let routes = Array.from([
    ReturnBillModel,
    CommonWaybillModel,
    CommonWaybillDetailModel,
    WaybillModel,
    WaybillDetailModel,
    AccidentModel,
    AccidentProcessModel,
    AccidentDocumentModel,
    WaybillProfitItemModel,
    WaybillPoolModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));

routes = routes.concat([
    {
        path: "/shipment/commonWaybill/add",
        name: 'addCommonWaybill',
        component: routerUtils.importPage('shipment/view/commonWaybillEdit'),
        meta: {
            title: "新增普通运单"
        }
    },
    {
        path: "/shipment/commonWaybill/edit/:id",
        name: 'editCommonWaybill',
        component: routerUtils.importPage('shipment/view/commonWaybillEdit'),
        meta: {
            title: "修改普通运单"
        }
    },
    {
        path: "/shipment/accident/add",
        component: routerUtils.importPage('shipment/view/accidentEdit'),
        meta: {
            title: "新增事故"
        }
    },
    {
        path: "/shipment/accident/edit/:id",
        component: routerUtils.importPage('shipment/view/accidentEdit'),
        meta: {
            title: "修改事故"
        }
    },
    {
        path: "/shipment/accident/view/:id",
        name: 'viewAccidentDetail',
        component: routerUtils.importPage('shipment/view/accidentViewPage'),
        meta: {
            title: "事故详情"
        }
    }
]);

export default routes;