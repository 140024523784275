// 异步加载页面组件
const importPage = (view, path) => () =>
    import(/* webpackChunkName: "pages/[request]" */ `@/${path ? path : 'modules'}/${view}.vue`)

const templateRouter = (model) => {

    let instance = model.getInstance();

    let tmp = instance.getApi().split("/"),
        // eslint-disable-next-line no-unused-vars
        empty = tmp.shift(),
        app = tmp.shift(),
        module = tmp.shift(),
        view = `${app}/view/${module}`;

    return {
        path: instance.getApi(),
        name: instance.getApi(),
        component: importPage(view),
        meta: {
            title: instance.name
        }
    };
}

export default {
    importPage,
    templateRouter
};
