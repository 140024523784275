import BaseModel from "../../../common/baseModel";

export default class WarehouseModel extends BaseModel {

    name = "仓库";
    api = "/storage/warehouse";

    fields() {
        return [

    ];
}

}
