import BaseModel from "../../../common/baseModel";

export default class ShipmentMileageModel extends BaseModel {

    name = "结算里程";
    api = "/settlement/shipmentMileage";

    fields() {
        return [
            {
                key: "fromRegion",
                inputControl: "region"
            },
            {
                key: "toRegion",
                inputControl: "region"
            }
        ];
    }

}
