import WaybillModel from "../../shipment/model/WaybillModel";

export default class ArtificialWaybillModel extends WaybillModel {

    name = "驾运运单";
    api = "/truck/artificialWaybill";
    detailApi = "/truck/artificialWaybillDetail";

    awbFields = [
        {
            key: "driver",
            labelField: "fullInfo"
        }
    ];

    columnWidth = 6;

    formGrouping = [
        {label: "路线信息", columns: ["consignor", "consignorBase", "startArea", "customer", "unloadAreas"]},
        {label: "承运信息", columns: ["driver", "tempTruckNo", "loadUnloadType"]},
        {label: "付款信息", columns: ["totalAmount", "prePay", "arrivePay", "receivePay", "oilCardPay", "factoryOilPay", "wanjinOilPay", "deposit", "insuranceFee", "otherFee", "additionalPay"]}
    ];

    fields() {
        return super.fields().concat(this.awbFields);
    }

}
