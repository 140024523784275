import BaseModel from "../../../common/baseModel";

export default class CustomerModel extends BaseModel {

    name = "客户";
    api = "/customer/customer";

    fields() {
        return [
        {
            key: "area",
            inputControl: "region"
        }
    ];
}

}
