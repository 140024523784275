import routerUtils from "@/router/routerUtils";

import WarehouseModel from "./model/WarehouseModel";
import WarehouseAreaModel from "./model/WarehouseAreaModel";
import StorageLocationModel from "./model/StorageLocationModel";
import InventoryModel from "./model/InventoryModel";
// IMPORT_HOLDER

const routes = Array.from([
    WarehouseModel,
    WarehouseAreaModel,
    StorageLocationModel,
    InventoryModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));



export default routes;