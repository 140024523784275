import BaseModel from "../../../common/baseModel";

export default class CustomerOrderDetailModel extends BaseModel {

    name = "订单明细";
    api = "/order/customerOrderDetail";

    fields() {
        return [
        {
            key: "startArea",
            detailEditable: true,
            inputControl: "region"
        },
        {
            key: "destination",
            detailEditable: true,
            inputControl: "region"
        },
        {
            key: "customer",
            detailEditable: true,
        },
        {
            key: "returnBillNo",
            detailEditable: true,
        },
        {
            key: "head",
            visibleInGrid: false
        },
        {
            key: "productSn",
            foreignKey: "symbol"
        }
    ];
}

}
