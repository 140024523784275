import BaseModel from "../../../common/baseModel";

export default class ArrivalNoticeDetailModel extends BaseModel {

    name = "预计到货明细";
    api = "/stockIn/arrivalNoticeDetail";

    fields() {
        return [
        {
            key: "storageLocation",
            detailEditable: true,
            visibleInForm: true,
            systemGenerated: false,
            preload: true
        }
    ];
}

}
