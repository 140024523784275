import BaseModel from "../../../common/baseModel";

export default class ArrivalNoticeModel extends BaseModel {

    name = "预计到货";
    api = "/stockIn/arrivalNotice";
    detailApi = "/stockIn/arrivalNoticeDetail";

    columnWidth = 6;

    gridExpandable = true;

    fields() {
        return [
        {
            key: "needReceiveQuantity",
            visibleInForm: false
        },
        {
            key: "consignor",
            inputControl: "combo",
            preload: true
        },
        {
            key: "details",
            visibleInForm: false,
            visibleInGrid: false
        },
        {
            key: "symbol",
            columnType: "expand"
        },
        {
            key: "remark",
            columnIndex: -1
        }
    ];
}

}
