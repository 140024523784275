import BaseModel from "../../../common/baseModel";

export default class BranchOfficeModel extends BaseModel {

    name = "分支机构";
    api = "/org/branchOffice";

    fields() {
        return [
        {
            key: "company",
            editable: false
        }
    ];
}

}