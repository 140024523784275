export default {
    data() {
        return {

        };
    },
    methods: {
        showExportDialog() {
            this.doExport(this);
        },
        doExport(tableInstance) {
            let data = {
                    api: tableInstance.modelInstance.getApi(),
                    columns: Array.from(tableInstance.visibleColumns, column => column.key),
                    filters: []
                };

            Object.keys(tableInstance.submitedFilters).forEach(key => {
                data.filters.push({
                    property: key,
                    value: tableInstance.submitedFilters[key]
                });
            });

            this.axios.post(data.api + "/export", data).then(() => {
                this.$message.success("提交导出任务成功，稍后请在右上角导出任务中查看");
            });
        }
    }
};