import ProductSnModel from "../../product/model/ProductSnModel";
import app from "../../../main";
import ComConfigure from "../../../common/configure";

const cc = ComConfigure.getInstance();

export default class TruckModel extends ProductSnModel {

    name = "商品车";
    api = "/truck/truck";
    columnWidth = 6;

    quickEdit = false;

    formGrouping = [
        {label: "基础信息", columns: [
                "product", "symbol", "consignor", "consignorBase", "billNo", "wheelbase", "containerType",
                "cabType", "engineModel", "color", "engineNo", "truckPrice", "displacement",
                "status", "returnBillExternalStatus", "collected",
                "containerLength",
                "containerWidth",
                "containerHeight",
        ]},
        {label: "日期信息", columns: [
                "billDownloadDate", "shouldShipDate", "billDateLimit", "inStorageDate", "shipDate"
            ]},
        {label: "客户信息",
            columns: [
                "customer", "destination", "destinationProvince", "destinationCity", "destinationStreet"
            ]}
    ];

    truckFields = [
        {
            key: "symbol",
            label: "VIN",
            displayComponent: "@/modules/truck/components/truckSymbolDetailBtn"
        },
        {
            key: "returnBillImage",
            inputControl: "idUpload",
            inputControlProps: {
                type: "TableOCR"
            },
        },
        {
            key: "checkVIN",
            virtual: true,
            inputControl: "switch",
            visibleInGrid: false
        },
        {
            key: "snStandard",
            editable: false,
            filterable: {
                type: "string"
            },
        },
        {
            key: "destinationCity",
            width: 80
        },
        {
            key: "destinationStreet",
            width: 80
        },
        {
            key: "product",
            remoteQueryField: "name|symbol",
            getDisplay(item) {
                if(!item) {
                    return "";
                }
                return `[${item.symbol}] ${item.name}`;
            },
            filterable: {
                key: "product",
                type: "combo"
            }
        },
        {
            key: "storageLocation",
            preload: true,
            filterable: {
                type: "select",
                key: "slId"
            }
        },
        {
            key: "consignor",
            inputControl: "display",
            toString(consignor) {
                return consignor ? consignor.name : "";
            },
            filterable: {
                key: "consignorId",
                type: "combo"
            }
        },
        {
            key: "status",
            visibleInForm: false,
        },
        {
            key: "destination",
            inputControl: "region",
            toString(destination) {
                return destination ? destination.replace(/ /g, " / ") : "";
            }
        },
        {
            key: "startArea",
            inputControl: "region"
        },
        {
            key: "billDownloadDate",
            width: 100,
            value: function() {
                return app.$moment(new Date()).format();
            }
        },
        {
            key: "inStorageDate",
            width: 100,
            visibleInForm: false
        },
        {
            key: "customer",
            filterable: {
                key: "customerId",
                type: "combo"
            }
        },
        {
            key: "wheelbase",
            inputControl: "select",
            getStaticStore() {
                return cc.getStaticStore("Wheelbase");
            }
        },
        {
            key: "containerType",
            inputControl: "select",
            getStaticStore() {
                return cc.getStaticStore("ContainerType");
            }
        },
        {
            key: "displacement",
            inputControl: "select",
            getStaticStore() {
                return cc.getStaticStore("EngineDisplacement");
            }
        },
        {
            key: "cabType",
            inputControl: "select",
            getStaticStore() {
                return cc.getStaticStore("CabType");
            }
        },
        {
            key: "engineModel",
            inputControl: "select",
            getStaticStore() {
                return cc.getStaticStore("EngineModel");
            }
        },
        {
            key: "color",
            inputControl: "select",
            getStaticStore() {
                return cc.getStaticStore("Color");
            }
        }
    ];

    fields() {
        return super.fields().concat(this.truckFields);
    }

}
