import routerUtils from "@/router/routerUtils";

import PolicyModel from "./model/PolicyModel";
// IMPORT_HOLDER

let routes = Array.from([
    PolicyModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));

routes = routes.concat([
    {
        path: "/policy/policy/add",
        component: routerUtils.importPage('policy/view/policyEdit'),
        meta: {
            title: "新增策略"
        }
    },
    {
        path: "/policy/policy/edit/:id",
        component: routerUtils.importPage('policy/view/policyEdit'),
        meta: {
            title: "修改策略"
        }
    },
]);
export default routes;