import app from "../../../main";
import DriverModel from "./DriverModel";

export default class ArtificialDriverModel extends DriverModel {

    name = "驾运司机";
    api = "/carrier/artificialDriver";

    quickEdit = false;

    printableImage = ['idCard', 'driveLicense', 'bankCard', 'contract'];

    fields() {
        return super.fields().concat([
            {
                key: "contractDate",
                value: function() {
                    return app.$moment(new Date()).format();
                }
            },
            {
                key: "contractDateEnd",
                value: function() {
                    return app.$moment(new Date()).add(1, "year").subtract(1, "day").format();
                }
            },
        ]);
    }
}
