<template>
  <el-form :class="formCls"
           size="mini"
           :label-suffix="labelSuffix"
           :model="formData"
           :status-icon="!showMessage"
           :show-message="showMessage"
           :rules="definedRules" ref="form">
    <el-row v-for="(group, gi) in grouping" :key="group.label">
      <el-divider content-position="left" v-if="showGroupHead !== false && group.showHead !== false">
        <span v-if="grouping.length > 1">{{gi+1}}、</span>
        {{group.length > 1 ? (defaultGroupLabel || group.label) : group.label}}
      </el-divider>
      <div v-else style="height:8px; overflow:hidden; width:100%;"></div>
      <el-col :span="column.columnWidth" v-for="column in group.columns"
              v-show="column && column.visibleInForm !== false && column.systemGenerated !== true"
              :key="column.key">
        <el-form-item v-if="column && column.visibleInForm !== false && column.systemGenerated !== true"
                      :prop="column.key"
                      :label-width="column.labelWidth === undefined ? labelWidth : column.labelWidth"
                      :label="(column.showFormLabel !== false && column.labelWidth !== '0') ? (column.label || column.key) : ''"
        >
          <slot :name="`custom-form-input_${column.key}`" :formData="formData" :column="column">
            <input-control
              :ref="`inputControl_${column.key}`"
              v-model="formData[column.key]"
              :form-data="formData"
              @input="formItemChanged"
              :column-options="column"></input-control>
          </slot>
        </el-form-item>
      </el-col>
    </el-row>
    <slot name="extra-column" :data="formData"></slot>
    <el-divider v-if="showActions"></el-divider>
    <el-form-item :label-width="labelWidth" v-if="showActions" class="form-actions">
      <el-button size="small" type="primary" @click="formSubmit">保存</el-button>
      <!--            <el-button size="small" v-if="!isEdit" class="ml-10" @click="submitAndContinue">保存并继续添加</el-button>-->
      <el-button size="small" class="ml-10" @click="formReset">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import FormValidator from "./FormValidator";
import InputControl from "../components/InputControl";
import DataStructure from "../dataStructure";

export default {
  name: "BaseForm",
  components: {
    InputControl
  },
  props: {
    data: {},
    columns: [],
    api: null,
    labelWidth: {
      default: "100px"
    },
    
    model: {},
    rules: {},
    options: {},
    
    defaultGroupLabel: {
      default: "请填写表单"
    },
    
    showActions: {
      default: true
    },
    
    formCls: {
      type: String
    },
    labelSuffix: {
      type: String
    },
    showGroupHead: {
      type: Boolean,
      default: true
    },
    showMessage: {
      type: Boolean,
      default: true
    },
    columnOptions: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  
  data() {
    return {
      formData: {},
      originalFormData: {},
      definedColumns: this.columns || null,
      definedRules: {},
      
      loading: false,
      isEdit: false,
      
      modelInstance: null,
      
      definedColumnsMap: {},
      
      grouping: [
        // {label: "", columns: []}
      ]
    };
  },
  mounted() {
    this.modelInstance = this.model.getInstance();
    let me = this;
    
    if(this.data) {
      this.isEdit = !!(Object.keys(this.data).length);
    } else {
      this.isEdit = false;
    }
    
    // 加载数据结构
    if(this.definedColumns === null) {
      this.loadColumns();
    }
    
    if(this.isEdit) {
      this.formData = this._.cloneDeep(this.data);
    }
    
    // 数据结构预处理
    this.definedColumns.forEach(column => {
      
      if(this.columnOptions[column.key]) {
        column = Object.assign(column, this.columnOptions[column.key]);
      }
      // 默认值
      let defaultValue = undefined;
      if(typeof column.value === "function") {
        defaultValue = column.value();
      } else {
        defaultValue = column.value;
      }
      
      // 默认值
      if(defaultValue !== undefined && !this.isEdit) {
        me.$set(me.formData, column.key, defaultValue);
      }
      
      switch (column.inputControl) {
        case "combo":
          
          // 默认值
          if(me.isEdit && me.data[column.key] && !column.preload) {
            column.options = [me.data[column.key]];
          }
          break;
      }
      
      me.$set(me.definedColumnsMap, column.key, column);
    });
    
    
    this.originalFormData = this._.cloneDeep(this.formData);
    this.definedRules = this.rules || FormValidator.getRules(this.definedColumns);
    
    
    // 分组
    if(!this.modelInstance.formGrouping) {
      this.grouping = [{
        label: this.defaultGroupLabel,
        columns: this.definedColumns
      }];
    } else {
      this.grouping = Array.from(this.modelInstance.formGrouping, group => {
        return {
          label: group.label,
          columns: Array.from(group.columns, column => {
            return this.modelInstance.fieldsMap[column] || null;
          }).filter(item => {
            return item;
          })
        };
      });
    }
    
    me.$forceUpdate();
  },
  
  methods: {
    loadColumns() {
      this.definedColumns = this.modelInstance.getFormColumns();
    },
    
    formSubmit() {
      let me = this;
      
      this.$refs.form.validate(function(res) {
        if(!res) {
          return;
        }
        let postData = DataStructure.getInstance().getFormSubmitValue(me.formData, me.modelInstance);
        
        me.loading = true;
        me.$emit("form-submit", postData, me.isEdit, true, (res) => {
          me.formData = res;
          me.formReset();
        }, () => {
          me.loading = false;
        })
      });
    },
    
    submitAndContinue() {
      return this.formSubmit(false);
    },
    
    formReset() {
      this.loading = false;
      if(!this.isEdit) {
        this.formData = this._.cloneDeep(this.originalFormData);
      }
      this.$nextTick(() => {
        if(this.$refs.form) {
          this.$refs.form.resetFields();
          this.$refs.form.clearValidate();
        }
        
        this.definedColumns.forEach(column => {
          this.updateControl(column, column.value)
        });
      })
    },
    
    updateData(column, value) {
      const columnConfig = this.definedColumnsMap[column] || {
        key: column
      };
      if(this.definedColumnsMap[column]) {
        
        columnConfig.inputControlProps = columnConfig.inputControlProps || {};
        
        let original = this.formData[column],
          tempObj = {};
        switch(this.definedColumnsMap[column].inputControl) {
          case "region":
            if(columnConfig.inputControlProps.multiple) {
              if(!this._.isArray(value)) {
                value = value.split(",");
              }
              if(!value.length) {
                return;
              }
              
              original = original || [];
              if(!this._.isArray(original)) {
                original = [original.replaceAll(" ", ",").split(",")];
              }
              value = original.concat(Array.from(value, item => {
                return this._.isArray(item) ? item : item.split(" ");
              }));
              
              value.forEach(item => {
                tempObj[item.toString()] = item;
              });
              
              value = Array.from(Object.keys(tempObj), item => {
                return item.split(",");
              });
            } else if(!this._.isArray(value)) {
              value = value?.replace(/[ \\/,]+/g, " ").split(" ");
            }
            break;
        }
      }
      
      this.$set(this.formData, column, value);
      this.updateControl(columnConfig, value);
    },
    updateControl(column, value) {
      if(!this.$refs[`inputControl_${column.key}`] || !this.$refs[`inputControl_${column.key}`][0]) {
        return;
      }
      this.$refs[`inputControl_${column.key}`][0].updateData(value);
    },
    formItemChanged(value, key, raw) {
      this.$emit("form-data-changed", this.formData);
      this.$emit("form-item-changed_" + key, value, this.formData, raw);
    },
    
  }
}
</script>

<style scoped>
.el-table__empty-block {
  min-height: 100px;
}

.grid-content {
  padding: 10px;
}

.form-actions {
  margin-top: 30px;
}
</style>
