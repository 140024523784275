<template>
    <el-dialog
        :width="$store.getters.isSmallView ? '95%' : '80%'"
        v-if="visible"
        :visible.sync="visible"
        :close="handleClose"
        title="查询">
        <el-form size="mini" label-width="90px" v-if="ready">
            <div v-for="(group, index) in grouping" :key="index">
                <el-divider content-position="left">{{group.label}}</el-divider>
                <el-row :gutter="20">
                    <div v-for="(column, ci) in group.columns.filter(x => ignoreFields.indexOf(x) < 0)" :key="ci">
                        <el-col :lg="fieldsMap[column].dateType === 'datetimerange' ? 12 : 6"
                                :md="fieldsMap[column].dateType === 'datetimerange' ? 12 : 8"
                                :sm="12"
                                :xs="24" v-if="fieldsMap[column]">
                            <el-form-item :label="fieldsMap[column].label">
                                <input-control v-model="queryData[column]" :form-data="queryData" :column-options="fieldsMap[column]" />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-row>
            </div>
        </el-form>

        <div slot="footer">
            <el-button type="primary" @click="handleQuery" size="small" icon="el-icon-search">查询</el-button>
            <el-button size="small" @click="handleClose">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import InputControl from "./InputControl";
export default {
    name: "ComplexQueryWindow",
    components: {InputControl},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        modelInstance: {
            type: Object,
            required: true
        }
    },
    computed: {
        visible: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    data() {
        return {
            grouping: [],
            fieldsMap: {},
            ignoreFields: [],

            queryData: {},

            ready: false
        }
    },
    mounted() {

        this.grouping = this.modelInstance.formGrouping || [{
            label: this.modelInstance.name,
            columns: Array.from(new Set(Array.from(this.modelInstance.getGridColumns(), item => item.key)))
        }]

        const ignoreInputControl = [
            "display", "idUpload"
        ];
        const rawFieldsMap = this._.cloneDeep(this.modelInstance.fieldsMap)
        Object.keys(rawFieldsMap).forEach(k => {
            const field = this._.cloneDeep(rawFieldsMap[k])
            if(k === 'createdDate' || k === 'updatedDate') {
                field.inputControl = "date"
            }
            switch(field.inputControl) {
                case "combo":
                case "select":
                    field.inputControlProps = field.inputControlProps || {};
                    field.inputControlProps.multiple = true;
                    break;
                case "date":
                    field.dateFormat = "yyyy-MM-dd HH:mm:ss";
                    field.dateType = "datetimerange";
                    break;
                case "region":
                    field.inputControl = "text"
                    break;
                case "switch":
                    field.inputControl = "combo"
                    field.type = "BOOLEAN"
                    field.inputControlProps = field.inputControlProps || {};
                    field.inputControlProps.multiple = true;
                    field.options = [{id: true, name: "是"}, {id: false, name: "否"}];
                    break;
            }

            if(field.preload) {
                field.remoteQuery("_", (res) => {
                    field.options = res.items
                }, this);
            }

            if(ignoreInputControl.indexOf(field.inputControl) >= 0) {
                this.ignoreFields.push(k);
            }

            if(field.virtual && (!field.filterable || !field.filterable.key)) {
                this.ignoreFields.push(k);
            }

            this.$set(this.fieldsMap, k, field);
        });

        this.ready = true;
    },
    methods: {
        handleClose() {
            this.$emit("input", false);
        },
        handleQuery() {
            const queryData = {}
            Object.keys(this.queryData).forEach(k => {
                const data = this.queryData[k]
                const field = this.fieldsMap[k]

                switch(field.type) {
                    case "FOREIGN":
                        if(data.length > 0) {
                            let key = k,
                                foreignQueryField = "id";
                            if(field.filterable) {
                                key = field.filterable.key;
                                if(field.filterable.type === 'string') {
                                    foreignQueryField = "name|symbol";
                                }
                            }
                            queryData[key] = `@${Array.from(data, item => item[foreignQueryField]).join(",")}`;
                        }
                        break;
                    case "ENUM":
                        if(data.length > 0) {
                            queryData[k] = `@${Array.from(data, item => item.id).join(",")}`;
                        }
                        break;
                    case "DATE":
                        queryData[k] = `#${data.join()}`;
                        break;
                    case "NUMBER":
                    case "INT":
                    case "BIGDECIMAL":
                        queryData[k] = data;
                        break;
                    case "BOOLEAN":
                        if(data instanceof Array && data.length) {
                            queryData[k] = `@${Array.from(data, item => item.id).join()}`;
                        }
                        break;
                    case "region":
                        queryData[k] = `%${data.join(" ")}%`;
                        break;
                    default:
                        if(data instanceof Array && data.length) {
                            queryData[k] = `@${data.join()}`;
                        } else if(data !== undefined && data.length) {
                            queryData[k] = `%${data}%`;
                        }
                }


                if(field.inputControl === 'display') {
                    this.ignoreFields.push(k);
                }
            });

            this.$emit("query", queryData)
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-input__inner {
    width: 100%
}
</style>
