import BaseModel from "../../../common/baseModel";

export default class StorageLocationModel extends BaseModel {

    name = "库位";
    api = "/storage/storageLocation";

    fields() {
        return [

    ];
}

}
