import routerUtils from "@/router/routerUtils";

import ChatRobotModel from "./model/ChatRobotModel";
// IMPORT_HOLDER

const routes = Array.from([
    ChatRobotModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));


export default routes;