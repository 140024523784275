<template>
    <div class="search" v-if="this.column && this.column.key">
        <el-input
            v-if="column.filterable.type === 'number'"
            size="mini"
            :clearable="true"
            v-model="filterData"
            @focus="handleInputFocus"
            :placeholder="filterOptions.placeholder || `按 ${ column.label} 检索`"/>
        <el-select
            v-else-if="column.filterable.type === 'select'"
            :clearable="true"
            size="mini"
            multiple
            v-model="filterData" :placeholder="filterOptions.placeholder || `按 ${column.label} 检索`">
            <el-option
                v-for="item in (column.options || column.staticStore || [])"
                :key="item.id"
                :label="getDisplay(column, item)"
                :value="item.id">
            </el-option>
        </el-select>
        <el-select
            v-else-if="column.filterable.type === 'combo'"
            filterable
            :remote="true"
            reserve-keyword
            :remote-method="(keyword) => {
                selectRemoteOptions(keyword, column)
            }"
            :clearable="true"
            size="mini"
            v-model="filterData" :placeholder="filterOptions.placeholder || `按 ${column.label} 检索`">
            <el-option
                v-for="item in (column.options || column.staticStore || [])"
                :key="item.id"
                :label="getDisplay(column, item)"
                :value="item.id">
            </el-option>
        </el-select>
        <el-date-picker
            v-else-if="column.filterable.type === 'date'"
            v-model="filterData"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            start-placeholder="开始"
            end-placeholder="结束"
            :value-format="column.dateFormat || 'yyyy-MM-dd'"
            :picker-options="dateRangeOptions">
        </el-date-picker>
        <el-input
            v-else
            size="mini"
            :clearable="true"
            v-model="filterData"
            @focus="handleInputFocus"
            :placeholder="filterOptions.placeholder || `按 ${column.label} 检索`"/>
    </div>
</template>

<script>
import __ from "lodash"
import DataStructure from "../dataStructure";
export default {
    name: "TableColumnFilter",
    props: {
        column: {
            type: Object,
            default() {
                return {}
            }
        },
        fuzzy: {
            type: Boolean,
            default: false
        }
    },
    data() {

        let moment = this.$moment;
        return {
            filterData: null,

            filterOptions: {},
            dateRangeOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(new Date(moment(start).format("YYYY-MM-DD 00:00:00")).getTime());
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        let yesterday = moment(start).subtract(-1, "day");
                        start.setTime(new Date(yesterday.format("YYYY-MM-DD 00:00:00")).getTime());
                        end.setTime(new Date(yesterday.format("YYYY-MM-DD 23:59:59")).getTime());
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            }
        };
    },

    mounted() {
        this.filterOptions = this.column.filterable = this.column.filterable || {};
    },

    watch: {
        filterData(val) {
            this.handleInputChange(val);
        }
    },

    methods: {
        handleInputFocus(e) {
            e.target.select();
        },
        handleInputChange: __.debounce(function(val) {

            const filterKey = this.filterOptions.key || this.column.key;

            let queryValue, start, end;
            switch(this.filterOptions.type.toLowerCase()) {
                case "string":
                    queryValue = val.trim();
                    if(this.filterOptions.fuzzy) {
                        queryValue = `%${queryValue}%`;
                    }
                    break;
                case "select":
                    if(val && val.length) {
                        queryValue = "@" + val.join();
                    }
                    break;
                case "date":
                    if(!this._.isArray(val) || val.length !== 2) {
                        queryValue = "";
                    } else {
                        start = val[0];
                        end = val[1];

                        queryValue = `#${this.$moment(start).format()},${this.$moment(end).format()}`;
                    }
                    break;
                default:
                    queryValue = val;
            }


            this.$emit("filter-item-changed", filterKey, queryValue);

        }, 500),

        selectRemoteOptions: __.debounce(function(keyword, column) {
            column.remoteQuery(keyword).then(() => {
                // this.$set(this.column, "options", column.options);
                this.$forceUpdate();
            }, this);
        }, 500),

        getDisplay: DataStructure.getInstance().getDisplay
    }
}
</script>

<style lang="scss">
.el-table.withColumnFilter th.el-table__cell {
    .cell {
        position: relative;
        padding-bottom: 30px !important;
    }

    .caret-wrapper {
        position: absolute;right: 10px;
        top: -5px;
    }

    .search {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0;
        input {
            padding: 0 10px;border: 0;
        }

        .el-date-editor {
            width: 100%;
            border: none;

            .el-icon-date {
                display: none;
            }

            input {
                padding:0;
                width: 40%;
                text-align: center;
            }

            .el-range-separator {
                width: 3%;
                color: #ddd;
                text-align: center;
            }
        }
    }
}
</style>
