import BaseModel from "../../../common/baseModel";

export default class DashboardWidgetModel extends BaseModel {

    name = "控制台控件";
    api = "/common/dashboardWidget";

    fields() {
        return [];
    }

}
