import BaseModel from "../../../common/baseModel";

export default class AccidentDocumentModel extends BaseModel {

    name = "事故资料";
    api = "/shipment/accidentDocument";

    fields() {
        return [
            {
                key: "resourceId",
                inputControl: "idUpload",
                inputControlProps: {
                    type: "Other",
                    accept: "*/*",

                },
                visibleInGrid: false
            },
            {
                key: "resource",
                inputControl: "idUpload",
                inputControlProps: {
                    type: "Other",
                    accept: "*/*",

                    getDownloadName(document) {
                        return document.name;
                    }
                },
                visibleInGrid: true,
                visibleInForm: false
            },
            {
                key: "resource",
                visibleInForm: false
            }
        ];
    }

}
