import BaseModel from "../../../common/baseModel";

export default class ConsignorBaseModel extends BaseModel {

    name = "货主出发地";
    api = "/customer/consignorBase";

    fields() {
        return [

    ];
}

}
