import routerUtils from "@/router/routerUtils";

import TruckModel from "./model/TruckModel";
import ArtificialWaybillModel from "./model/ArtificialWaybillModel";
import ArtificialWaybillDetailModel from "./model/ArtificialWaybillDetailModel";
import TruckIncomeItemModel from "./model/TruckIncomeItemModel";
// IMPORT_HOLDER

let routes = Array.from([
    TruckModel,
    ArtificialWaybillModel,
    ArtificialWaybillDetailModel,
    TruckIncomeItemModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));

routes = routes.concat([
    {
        path: '/truck/truck/add',
        name: 'AddTruck',
        component: routerUtils.importPage('truck/view/truckEdit'),
        meta: {
            title: "新增商品车"
        }
    },
    {
        path: '/truck/truck/edit/:truckId',
        name: 'editTruck',
        component: routerUtils.importPage('truck/view/truckEdit'),
    },
    {
        path: "/truck/artificialWaybill/add",
        name: 'addArtificialWayBill',
        component: routerUtils.importPage('truck/view/artificialWaybillEdit'),
        meta: {
            title: "新增驾运运单"
        }
    },
    {
        path: "/truck/artificialWaybill/edit/:billId",
        name: 'editArtificialWayBill',
        component: routerUtils.importPage('truck/view/artificialWaybillEdit'),
        meta: {
            title: "修改驾运运单"
        }
    },
    {
        path: '/truck/truckCollectRegistration',
        name: 'truckCollectRegistration',
        component: routerUtils.importPage('truck/view/truckCollectRegistration'),
        meta: {
            title: "提车登记"
        }
    },
    {
        path: '/truck/truckPool',
        name: 'truckPool',
        component: routerUtils.importPage('truck/view/truckPool'),
        meta: {
            title: "订单池"
        }
    },
    {
        path: '/truck/truckCollectionPool',
        name: 'truckCollectionPool',
        component: routerUtils.importPage('truck/view/truckCollectionPool'),
        meta: {
            title: "提车登记池"
        }
    }
]);

export default routes;
