import BaseModel from "../../../common/baseModel";

export default class ScheduledReportModel extends BaseModel {
    name = "定时报表";
    api = "/report/scheduledReport";
    fields() {
        return [
            {
                key: "messageTemplate",
                inputControl: "textarea",
                visibleInGrid: false
            },
            {
                key: "policy",
                inputControl: "combo",
                defaultQueryParams: {
                    policyType: "ScheduleReportSchemaBuild"
                }
            },
            {
                key: "sendToChatList",
                type: "FOREIGN",
                preload: true,
                inputControl: "combo",
                valueField: "id",
                inputControlProps: {
                    multiple: true
                },
                foreignApi: "/message/chatRobot",
            },
        ];
    }

}
