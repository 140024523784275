<template>
    <div class="tab-container" :class="$store.getters.isSmallView ? 'small-view-tab' : 'normal-view-tab'">
        <router-tab
            page-transition="page-fade"
            tab-transition="tab-scale"
            :tabs="routerTabs" />
        <div class="bottom-tabs">
            <el-container>
                <div class="tab-item"
                     @click.stop="() => handleBottomTabSwitch(tab)"
                     :class="activeTab === tab.label ? 'active' : ''"
                     v-for="(tab, index) in tabButtons" :key="index">
                    <i :class="`el-icon-${tab.icon}`"></i>
                    <div>{{tab.label}}</div>
                </div>
            </el-container>
        </div>
    </div>
</template>

<script>

export default {
    name: "TabsFrame",
    data() {
        return {
            routerTabs: [
                "/"
            ],
            tabButtons: [
                {
                    icon: "monitor",
                    label: "监控",
                    link: "/"
                },
                {
                    icon: "download",
                    label: "订单池",
                    link: "/truck/truckPool"
                },
                {
                    icon: "receiving",
                    label: "业务",
                    link: "/mobile/business"
                }
            ],
            activeTab: "监控"
        };
    },
    mounted() {
        if(this.$route.path.startsWith('/truck/truckPool')) {
            this.activeTab = "订单池";
        } else if(this.$route.path.startsWith('/mobile/business')) {
            this.activeTab = "业务";
        }
    },
    methods: {
        handleBottomTabSwitch(tab) {
            this.activeTab = tab.label;

            this.$router.push(tab.link);
        }
    }
}
</script>

<style lang="scss">
.tab-container {
    .bottom-tabs {
        position: fixed;
        bottom: 0;
        left:0;
        width: 100%;
        border-top: 1px solid #ddd;
        background: #f2f2f2;
        height: 65px;
        z-index: 99;
        justify-content: space-around;
        display: none;

        .tab-item {
            text-align: center;
            height: 100%;
            width: 33%;
            font-size: 12px;
            line-height: 160%;
            i {
                margin-top: 5px;
                font-size: 22px;
                height: 22px;
                display: block;
            }

            &.active {
                color: #409EFF;
            }
        }

        .el-container {
            justify-content: space-around;
            margin: 10px;
            height: 45px;
        }
    }

    &.small-view-tab {
        ::-webkit-scrollbar{
            width: 0; height: 0;background-color: #fff;
            display: none;
        }
        .router-tab-page > div {
            //height: 98% !important;
            //overflow: hidden;
            padding-bottom: 60px;
        }
        .router-tab__header {
            display: none !important;
        }

        .router-tab__container {
            margin: 0 !important;
        }

        .bottom-tabs {
            display: flex;
        }
    }
}

.router-tab__nav {
    padding-left: 5px !important;
}
.router-tab__item:first-child {
    border: none;
}
.router-tab__item {
    border: none !important;
    padding: 0 20px 0 15px !important;
}
.router-tab__item.router-link-exact-active {
    border-bottom: 3px solid #409EFF !important;
}
.router-tab__item.router-link-exact-active:first-child {
    border-left: none !important;
}

.router-tab__item.is-active, .router-tab__item:hover {
    color: #409EFF !important;
}
.router-tab {
    height: 100%;
}
.router-tab-page {
    position: absolute;
    left:0;top:0;
    width: 100%;
    height:100%;
    background: #fff;
}

.main-wrap-content {
    background: #f2f2f2;
    height: auto;
    min-height: 100%;
}

</style>
