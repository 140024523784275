import BaseModel from "../../../common/baseModel";

export default class AccidentProcessModel extends BaseModel {

    name = "事故处理进度";
    api = "/shipment/accidentProcess";

    fields() {
        return [];
    }

}
