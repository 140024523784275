import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import './modules/common/plugins/element.js'
import {initRouter} from "./router";
import http from "./common/http";
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
import moment from 'moment'
import config from "./config";
import _ from 'lodash'
import VueParticles from 'vue-particles'
import VueCropper from 'vue-cropper'
import store from "./common/store";
import {getURLParams} from "./common/utils";
import {hasPerm} from "@/common/permission"

// global data
Vue.prototype.globalData = {
    loginSource: getURLParams().loginSource || "PC"
};

http.initAxios(config.getRemoteBase(), Vue.prototype.globalData.loginSource);

Vue.use(VueParticles);
Vue.use(VueCropper);
Vue.prototype._ = _;
// moment
Vue.prototype.$moment = moment;
Vue.prototype.$moment.locale("zh-CN");
Vue.prototype.$moment.defaultFormat = "YYYY-MM-DD HH:mm:ss";

Vue.config.productionTip = false

Vue.use(RouterTab)
Vue.use(Vuex)

// common view
import { baseTable, baseForm } from "./common/view"
Vue.use(baseTable);
Vue.use(baseForm);

const router = initRouter();

const defaultFocus = Vue.directive("default-focus", {
    inserted: function (el) {
        el.querySelector('input').focus()
    }
})

const app = new Vue({
    router,
    defaultFocus,
    store,
    directives: {
        hasPerm,
    },
    render: h => h(App),
}).$mount('#app')

// error handler
const errorHandler = (error, vm)=>{
    console.error('Error:', error, vm);
    const msg = `${error}`;
    app.$alert(msg, '前端异常', {
        confirmButtonText: '确定'
    });
}

Vue.config.errorHandler = errorHandler;
Vue.prototype.$throw = (error)=> errorHandler(error,this);

export default app;
