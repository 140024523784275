import __ from 'lodash'
import {cityData} from "./CityData";

export const getForeignDisplay = (row, rowKey) => {
    if (!row) throw new Error('row is required when get row identity');
    if (typeof rowKey === 'string') {
        if (rowKey.indexOf('.') < 0) {
            return row[rowKey] === undefined ? "" : row[rowKey];
        }
        let key = rowKey.split('.');
        let current = row;
        for (let i = 0; i < key.length; i++) {
            current = current[key[i]];
            if(current === null || current === undefined) {
                return "";
            }
        }
        return current;
    } else if (typeof rowKey === 'function') {
        return rowKey.call(null, row);
    }
};

export const booleanStore = [
    {id: true, name: "是"},
    {id: false, name: "否"}
];


export const getURLParams = () => {
    const searchURL = location.search; // 获取到URL中的参数串
    const params = new URLSearchParams(searchURL);
     // 转换为普通对象

    let result = {};
    params.forEach((v, k) => {
        result[k] = v;
    });

    return result;
}

export const notNaN = (number) => {
    return isNaN(number) ? 0 : number;
}

export const confirmCloseForm = (obj, callback) => {
    obj.$confirm('您还没有保存，确定要关闭此表单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(callback).catch(() => {});
}

export const axiosDownload = (axios, url, options) => {

    options = __.isObject(options) ? options : {
        name: options
    };

    let queryOptions = options.queryOptions || {};

    queryOptions.responseType = 'blob';

    axios.get(url, queryOptions).then(res => {
        res.data.name = options.name;
        let href = window.URL.createObjectURL(res.data),
            downloadElement = document.createElement("a");

        downloadElement.href = href;
        downloadElement.download = options.name; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
    });
}

export const regionToObj = (region) => {

    if(!region) {
        return;
    }

    let tmp = region.split(" ");

    if(tmp.length < 3) {
        tmp[2] = tmp[1];
    }

    return {
        province: tmp[0],
        city: tmp[1],
        street: tmp[2]
    };
};

function areaMatch(original, allList) {
    let matchedLabel = null,
        matchedItem = null;

    for(let i = 0; i<allList.length; i++) {
        const a = allList[i];
        if(a.label === original) {
            matchedLabel = a.label;
            matchedItem = a
            break;
        }
    }

    if(matchedItem == null) {
        for(let i = 0; i<allList.length; i++) {
            const a = allList[i];
            if(a.label?.startsWith(original) || original?.startsWith(a.label)) {
                matchedLabel = a.label
                matchedItem = a
                break;
            }
        }
    }

    return {
        label: matchedLabel,
        item: matchedItem
    }
}

export const regionObjToArr = (regionObj) => {
    const result = []
    if(!regionObj.province) {
        return result;
    }
    result.push(regionObj.province)
    if(!regionObj.city) {
        return result;
    }

    result.push(regionObj.city)
    if(!regionObj.street) {
        return result;
    }

    result.push(regionObj.street)
    return result;
}

export const formatArea = (region) => {
    region = regionToObj(region);
    const result = {
        province: null,
        city: null,
        street: null
    }

    let provinceItem = areaMatch(region.province, cityData);
    if(!provinceItem.item) {
        return result;
    }

    result.province = provinceItem.label;
    let cityItem = areaMatch(region.city, provinceItem.item.children);
    if(!cityItem.item) {
        return result;
    }

    result.city = cityItem.label;

    if(!cityItem.item.children) {
        return result;
    }
    let streetItem = areaMatch(region.street, cityItem.item.children);
    if(streetItem.item) {
        result.street = streetItem.label;
    }

    return result;
}
