import WaybillModel from "./WaybillModel";
import ComConfigure from "../../../common/configure";

const cc = ComConfigure.getInstance();

export default class CommonWaybillModel extends WaybillModel {

    name = "普通运单";
    api = "/shipment/commonWaybill";
    detailApi = "/shipment/commonWaybillDetail";

    cwbFields = [
        {
            key: "truckLength",
            inputControl: "select",
            columnWidth: 3,
            getStaticStore() {
                return cc.getStaticStore("DeliveryTruckLength");
            }
        },
        {
            key: "containerType",
            inputControl: "select",
            required: true,
            columnWidth: 3,
            getStaticStore() {
                return cc.getStaticStore("DeliveryTruckContainerBox");
            }
        }
    ];

    columnWidth = 6;

    formGrouping = [
        {label: "路线信息", columns: ["consignor", "consignorBase", "startArea", "customer", "unloadAreas"]},
        {label: "承运信息", columns: ["driver", "truckNo", "loadUnloadType", "truckLength", "containerType"]},
        {label: "付款信息", columns: ["totalAmount", "prePay", "arrivePay", "receivePay", "oilCardPay", "factoryOilPay", "wanjinOilPay", "deposit", "insuranceFee", "otherFee", "returnBillNo", "additionalPay"]}
    ];


    fields() {
        return super.fields().concat(this.cwbFields);
    }

}
