import routerUtils from "@/router/routerUtils";

export default [
    {
        path: '/manage/company',
        name: 'ManageCompany',
        component: routerUtils.importPage('manage/view/company'),
        meta: {
            title: '企业'
        }
    },
    {
        path: '/manage/reports',
        name: 'ManageReport',
        component: routerUtils.importPage('manage/view/reportDesign'),
        meta: {
            title: '报表设置'
        }
    }
];
