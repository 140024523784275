import BaseModel from "../../../common/baseModel";

export default class RoleModel extends BaseModel {

    name = "岗位";
    api = "/org/role";

    fields() {
        return [
        {
            key: "roleType",
            visibleInGrid: false,
            editable: false
        },
        {
            key: "company",
            visibleInForm: false,
            visibleInGrid: false
        }
    ];
}

}
