import BaseModel from "../../../common/baseModel";

export default class AccidentModel extends BaseModel {

    name = "事故";
    api = "/shipment/accident";

    formGrouping = [
        {label: "基本信息", columns: ['symbol', 'billSymbol', 'chargePerson', 'lostAmount',
                'driver',
                'happenDate', 'happenRegion', 'happenAddress',
                'latestProcessDate', 'latestProcess'
            ]},
        {label: "受损情况", columns: ['driverDamage', 'goodsDamage', 'otherDamage', 'damageInfo']},
        {label: "事故经过", columns: ['accidentContent']}
    ];

    columnWidth = 6;

    quickEdit = false;

    fields() {
        return [
            {
                key: "happenRegion",
                inputControl: "region",
                visibleInGrid: false
            },
            {
                key: "happenDate",
                visibleInGrid: false
            },
            {
                key: "chargePerson",
                visibleInGrid: false
            },
            {
                key: "billSymbol",
                visibleInGrid: false
            },
            {
                key: "done",
                visibleInGrid: false,
                visibleInForm: false,
            },
            {
                key: "waybill",
                visibleInGrid: false,
                visibleInForm: false,
            },
            {
                key: "latestProcessDate",
                visibleInGrid: false
            },
            {
                key: "endDate",
                visibleInGrid: false,
                visibleInForm: false,
            },
            {
                key: "lostAmount",
                visibleInGrid: false
            },
            {
                key: "happenRegion",
                inputControl: "region",
                visibleInGrid: false
            },
            {
                key: "driverDamage",
                columnWidth: 4,
                visibleInGrid: false
            },
            {
                key: "goodsDamage",
                columnWidth: 4,
                visibleInGrid: false
            },
            {
                key: "otherDamage",
                columnWidth: 4,
                visibleInGrid: false
            },
            {
                key: "damageInfo",
                columnWidth: 12
            },
            {
                key: "documents",
                visibleInForm: false,
                visibleInGrid: false
            },
            {
                key: "documentsSummary",
                visibleInForm: false
            },
            {
                key: "processes",
                visibleInForm: false,
                visibleInGrid: false
            },
            {
                key: "latestProcess",
                columnWidth: 18
            },
            {
                key: "accidentContent",
                inputControl: "rich",
                visibleInGrid: false,
                columnWidth: 24,
                labelWidth: "0",
                inputControlProps: {
                    modules: {
                        toolbar: [
                            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                            ["blockquote", "code-block"], // 引用  代码块
                            // [{ header: 1 }, { header: 2 }], // 1、2 级标题
                            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
                            // [{ script: "sub" }, { script: "super" }], // 上标/下标
                            // [{ indent: "-1" }, { indent: "+1" }], // 缩进
                            // [{'direction': 'rtl'}],                         // 文本方向
                            [{ size: ["small", false, "large", "huge"] }], // 字体大小
                            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                            // [{ font: [] }], // 字体种类
                            [{ align: [] }], // 对齐方式
                            ["clean"], // 清除文本格式
                            // ["link", "image", "video"] // 链接、图片、视频
                        ], //工具菜单栏配置
                    },
                    placeholder: '请在这里添加事故描述', //提示
                    theme: 'snow', //主题 snow/bubble
                }
            }
        ];
    }

}
