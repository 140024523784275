import BaseModel from "../../../common/baseModel";

export default class ProductSnModel extends BaseModel {

    name = "序列号";
    api = "/product/productSn";

    columnWidth = 11;

    fields() {
        return [
            {
                key: "snStandard",
                editable: false,
                filterable: false
            },
            {
                key: "product",
                defaultQueryParams: {
                    snManagement: true
                }
            },
            {
                key: "attributes",
                visibleInGrid: false,
                visibleInForm: false
            }
        ];
    }

}
