export default {
    env: {
        prod: {
            remoteBase: "https://api.cloud.luyun.top",
            host: "b.cloud.luyun.top"
        },
        dev: {
            remoteBase: "http://localhost:9400",
            // remoteBase: "https://api.cloud.luyun.top",
            host: "localhost:8080"
        }
    },

    getRemoteBase() {
        let currentEnv = "dev";
        Object.keys(this.env).forEach(env => {
            if(window.location.host === this.env[env].host) {
                currentEnv = env;
            }
        });

        return this.env[currentEnv].remoteBase;
    }
}