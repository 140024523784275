import BaseModel from "../../../common/baseModel";

export default class OperationLogModel extends BaseModel {

    name = "操作日志";
    api = "/common/operationLog";

    fields() {
        return [
            {
                key: "operation",
                width: 180
            }
        ];
    }

}
