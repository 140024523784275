import BaseModel from "../../../common/baseModel";

export default class PaymentBillModel extends BaseModel {

    name = "付款单";
    api = "/payment/paymentBill";

    gridExpandable = true;

    fields() {
        return [
            {
                key: "status",
                visibleInGrid: false
            },
            {
                key: "subject",
                width: 200
            },
            {
                key: "remark",
                width: 200
            },
            {
                key: "procInstanceId",
                width: 200
            },
            {
                key: "procId",
                visibleInGrid: false
            },
            {
                key: "procInstanceBillNo",
                visibleInGrid: false
            }
        ];
    }
}
