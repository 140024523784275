<template>
    <i :class="getIcon()" class="file-icon"></i>
</template>

<script>
export default {
    name: "FileTypeIcon",
    props: {
        ext: {
            type: String,
            default: "file"
        }
    },
    data() {
        return {
            extIconMap: {
                'xls': 'excel',
                'xlsx': 'excel',
                'doc': 'word',
                'docx': 'word',
                'ppt': 'ppt',
                'pptx': 'ppt',
                'zip': 'zip',
                'rar': 'rar',
                'pdf': 'PDF',
                'mp4': 'wenjianleixing-biaozhuntu-shipinwenjian',
                'mov': 'wenjianleixing-biaozhuntu-shipinwenjian',
                'avi': 'wenjianleixing-biaozhuntu-shipinwenjian',
                'wmv': 'wenjianleixing-biaozhuntu-shipinwenjian',
                'mpeg': 'wenjianleixing-biaozhuntu-shipinwenjian',
                'mp3': 'jianzhuanquan-',
                'wav': 'jianzhuanquan-',
            },
        };
    },
    methods: {
        getIcon() {
            let type = this.extIconMap[this.ext] || 'wenjian';
            return `iconfont icon-${type}`;
        }
    }
}
</script>

<style scoped>

</style>