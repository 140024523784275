<template>
    <div v-if="dataRow">
        <div class="field-display-function"
             v-html="column.getDisplay(dataRow[column.key], dataRow)"
             v-if="typeof column.getDisplay === 'function'">
        </div>
        <div class="field-display-idUpload" v-else-if="column.inputControl === 'idUpload'">
            <el-button :size="size" v-if="dataRow[column.key]" @click="showUploadedResource(dataRow, column)" :type="btnType">
                {{uploadDisplayText}}
            </el-button>
        </div>
        <div class="field-display-region" v-else-if="column.inputControl === 'region'">
            {{_.isArray(dataRow[column.key]) ? dataRow[column.key].join(" ") : dataRow[column.key]}}
        </div>
        <div v-else-if="column.inputControl === 'rich'"
             class="field-display-rich rich-text" v-html="dataRow[column.key]"></div>
        <div class="field-display-boolean" v-else-if="column.type === 'BOOLEAN'">
            <i :class="getForeignDisplay(dataRow, column.key) ? 'el-icon-success green bold' : 'el-icon-circle-close'"></i>
        </div>
        <div class="field-display-foreign" v-else-if="column.type === 'FOREIGN'"
             v-html="getForeignDisplay(dataRow, column.key + '.' + column.foreignKey)">

        </div>
        <div class="field-display-enum" v-else-if="column.type === 'ENUM'">
            {{getEnumDisplay(api, column.key, dataRow[column.key], dataRow)}}
        </div>
        <div class="field-display-date" v-else-if="column.type === 'DATE' && getForeignDisplay(dataRow, column.key)">
            <el-tooltip placement="top" v-if="column.displayType !== 'raw'">
                <div slot="content">{{$moment(getForeignDisplay(dataRow, column.key)).format("yyyy-MM-DD HH:mm:ss")}}</div>
                <span>{{$moment(getForeignDisplay(dataRow, column.key)).format("yyyy-MM-DD")}}</span>
            </el-tooltip>
            <span v-else>{{$moment(getForeignDisplay(dataRow, column.key)).format("yyyy-MM-DD")}}</span>
        </div>
        <div class="field-display-default"
             v-else
             v-html="getForeignDisplay(dataRow, column.key)"></div>

        <el-dialog
            :title="currentResourceTitle"
            :visible.sync="imageDialogVisible"
            width="70%"
            append-to-body
            :before-close="handleUploadedResourceDialogClose">
            <div class="text-center">
                <el-image fit="contain" :src="currentImage"></el-image>
            </div>
            <div class="mt-10 text-center">
                <el-button size="small" :disabled="!currentImage" type="primary" @click="uploadedResourceDownload(currentResource)">下载</el-button>
                <el-button size="small" :disabled="!currentImage" class="ml-10" @click="uploadedResourceDownload(currentResource, true)">下载原图</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getForeignDisplay, axiosDownload} from "../utils";
import DataStructure from "../dataStructure";
import config from "../../config";

export default {
    name: "FieldDisplay",
    props: {
        row: {},
        val: {},
        btnType: {
            default: "text"
        },
        column: {},
        api: {},

        uploadDisplayText: {
            default: "点击查看"
        },
        size: {
            default: "mini"
        }
    },
    data() {
        return {
            currentImage: null,
            currentResource: null,
            currentResourceTitle: null,
            imageDialogVisible: false,

            dataRow: {}
        };
    },
    methods: {
        getForeignDisplay: getForeignDisplay,
        getEnumDisplay: DataStructure.getInstance().getEnumDisplay,

        showUploadedResource(dataRow, column) {
            let id = dataRow[column.key];
            if(this._.isObject(id)) {
                id = id.id;
            }
            this.axios.get("/common/uploadedResource/" + id).then(res => {
                if(res.image) {
                    this.currentImage = config.getRemoteBase()
                        + res.imageUrl
                        + "?token="
                        + localStorage.getItem("token")
                        + "&_loginSource="
                        + this.globalData.loginSource
                    ;
                    this.currentResourceTitle = column.label;
                    this.currentResource = res;
                    this.imageDialogVisible = true;
                } else {
                    this.uploadedResourceDownload(res);
                }
            });
        },
        handleUploadedResourceDialogClose() {
            this.currentImage = this.currentResourceTitle = null;
            this.imageDialogVisible = false;
        },
        uploadedResourceDownload(item, raw) {
            let data = {
                raw: !!raw
            };

            if(this.column.inputControlProps && typeof this.column.inputControlProps.getDownloadName === 'function') {
                data.name = this.column.inputControlProps.getDownloadName(this.currentResource);
            }

            let url = "/common/uploadedResource/download/" + this.currentResource.id;
            axiosDownload(this.axios, url, item.name);
        }
    },
    mounted() {
        if(!this.row) {
            this.dataRow = {};
            this.$set(this.dataRow, this.column.key, this.val);
        } else {
            this.dataRow = this.row;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
