import BaseModel from "../../../common/baseModel";

export default class WarehouseAreaModel extends BaseModel {

    name = "库区";
    api = "/storage/warehouseArea";

    fields() {
        return [

    ];
}

}
