import BaseModel from "../../../common/baseModel";
import app from "../../../main.js";

export default class CarrierModel extends BaseModel {

    name = "承运商";
    api = "/carrier/carrier";

    fields() {
        return [
        {
            key: "contractDate",
            value: function() {
                return app.$moment(new Date()).format();
            }
        },
        {
            key: "contractDateEnd",
            value: function() {
                return app.$moment(new Date()).add(1, "year").subtract(1, "day").format();
            }
        },
    ];
}

}
