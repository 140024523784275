import BaseTable from "./BaseTable";
import BaseForm from "./BaseForm";

export const baseTable = {
    install:function(Vue) {
        Vue.component('BaseTable', BaseTable)
    }
}

export const  baseForm = {
    install:function(Vue) {
        Vue.component('BaseForm', BaseForm)
    }
}
