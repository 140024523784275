import BaseModel from "../../../common/baseModel";

export default class ProductModel extends BaseModel {

    name = "产品";
    api = "/product/product";

    fields() {
        return [

    ];
}

}
