import BaseModel from "../../../common/baseModel";
import app from "../../../main";
import _ from "lodash"

export default class NeedPayModel extends BaseModel {

    name = "应付款";
    api = "/payment/needPay";

    selectedToApprove = [];

    fields() {
        return [
            {
                key: "paymentStatus",
                // visibleInGrid: false
            },
            {
                key: "needPayType",
                // visibleInGrid: false
            },
            {
                key: "paymentBill",
                // visibleInGrid: false
            },
            {
                key: "symbol",
                filterable: {
                    type: "String",
                    key: "symbol|pbSymbol",
                    placeholder: "应付款编号/付款单号"
                }
            },
            {
                key: "lineInfo",
                label: "路线信息",
                filterable: {
                    type: "String",
                    key: "waybill.startArea|waybill.unloadAreas"
                }
            },
            {
                key: "driver",
                filterable: {
                    type: "String",
                    key: "driverName|driverMobile",
                    fuzzy: true,
                    placeholder: "按姓名或电话检索"
                }
            },
            {
                key: "bankCard",
                filterable: {
                    type: "String",
                    key: "bankCardNo|bankUser|bank",
                    fuzzy: true,
                    placeholder: "收款人/卡号/银行"
                }
            },
            {
                key: "waybill",
                remoteQueryField: "symbol",
                filterable: {
                    type: "String",
                    fuzzy: true,
                    key: "waybill.symbol|waybill.returnBillNo|waybill.summary",
                    placeholder: "运单号/回单号/承运信息"
                }
            },
            {
                key: "waybillRemark",
                label: "运单备注",
                getDisplay(v, row) {
                    if(!row.waybill || !row.waybill.remark) {
                        return "";
                    }
                    const result = []
                    row.waybill.remark.split(";").forEach(item => {
                        result.push(`<div>${item}</div>`);
                    })
                    return result.join("")
                }
            }
    ];
}

    addToApprove(needPays) {
        let me = this;

        let existsNum = me.selectedToApprove.length;
        let addNum = needPays.length;
        me.selectedToApprove = _.uniq(me.selectedToApprove.concat(
            Array.from(needPays, item => item.id)
        ));

        return {
            list: this.selectedToApprove,
            addNum: addNum,
            addedNum: me.selectedToApprove.length - existsNum,
            existsNum: existsNum
        };
    }

    removeFromApprove(needPayId) {
        _.remove(this.selectedToApprove, needPayId);
        return this.selectedToApprove;
    }

    generatePaymentBill(subject, remark, callback) {

        if(!this.selectedToApprove.length) {
            return;
        }

        let postData = {
            needPays: this.selectedToApprove,
            remark: remark,
            subject: subject
        };

        app.axios.post("/payment/paymentBill", postData).then(res => {
            if(typeof callback === "function") {
                callback(res);
            }

            app.$message.success("创建付款单成功");
        });

    }
}


