<template>
    <div class="main-container">
        <div class="login-container">
            <div class="logo mb-10" v-if="logoImg">
                <el-image :src="logoImg"></el-image>
            </div>
            <el-card class="box-card" shadow="hover">
                <div slot="header" class="clearfix">
                    <span>{{ passwordLogin ? "密码登录" : "手机验证码登录" }}</span>
                    <el-button
                        @click="passwordLogin = !passwordLogin"
                        style="float: right; padding: 3px 0" type="text">
                        切换{{ !passwordLogin ? "密码登录" : "手机验证码登录" }}</el-button>
                </div>

                <el-form ref="form" :model="formData" label-width="80px" @submit="login">
                    <el-form-item label-width="0">
                        <el-input v-model="formData.mobile"
                                  maxlength="11"
                                  @keyup.native="checkMobile"
                                  minlength="11"
                                  placeholder="手机号码"
                                  clearable>
                            <i
                                class="el-icon-mobile el-input__icon"
                                slot="prefix">
                            </i>
                        </el-input>
                    </el-form-item>
                    <el-form-item label-width="0" v-if="passwordLogin">
                        <el-input v-model="formData.password"
                                  @keydown.enter="login"
                                  show-password placeholder="密码" clearable>
                            <i
                                class="el-icon-s-promotion el-input__icon"
                                slot="prefix">
                            </i>
                        </el-input>
                    </el-form-item>
                    <el-form-item label-width="0" v-if="!passwordLogin">
                        <el-row :gutter="10">
                            <el-col :span="14">
                                <el-input v-model="formData.code"
                                          maxlength="6"
                                          placeholder="短信验证码"
                                          clearable style="width: 220px">.
                                    <i
                                        class="el-icon-s-promotion el-input__icon"
                                        slot="prefix">
                                    </i>
                                </el-input>
                            </el-col>
                            <el-col :span="10">
                                <el-button
                                    @click.prevent="sendVerifyCode"
                                    :disabled="!mobileChecked || verifyCodeSend > 0 || sendingSMS">
                                    {{verifyCodeSend > 0 ? verifyCodeSend + 's 后重发' : '发送验证码'}}
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item label-width="0">
                        <el-button type="primary"
                                   style="width: 100%"
                                   @click="login"
                                   :disabled="!mobileChecked || (!formData.password && !formData.code)">登 陆</el-button>
                    </el-form-item>

                </el-form>
                <div class="operations-container">
                    <el-checkbox v-model="formData.rememberMe">记住我</el-checkbox>
                    <div >
                        <el-link :underline="false" icon="el-icon-user">注册新用户</el-link>
                        <el-link :underline="false" class="ml-10" icon="el-icon-s-opportunity">忘记密码</el-link>
                    </div>
                </div>
            </el-card>

            <div class="grey text-center f-small mt-10" @click="showDebug">Powered by 中舜智运 &copy; since 2019</div>
            <div class="text-center f-small grey" v-if="roleType === 'Manager'">
                {{roleType}}
            </div>
        </div>

        <vue-particles class="login-bg"
                       color="#ddd"
                       :particleOpacity="0.7"
                       :particlesNumber="100"
                       shapeType="circle"
                       :particleSize="3"
                       linesColor="#8DD1FE"
                       :linesWidth="1"
                       :lineLinked="true"
                       :lineOpacity="0.4"
                       :linesDistance="150"
                       :moveSpeed="1"
                       :hoverEffect="true"
                       hoverMode="grab"
                       :clickEffect="true"
                       clickMode="push"></vue-particles>
    </div>
</template>

<script>
import logo from "../../assets/logo-colored.png"

export default {
    name: "Landing",
    props: {
        roleType: {
            type: String,
            default: "Company"
        }
    },
    data() {
        return {
            passwordLogin: true,
            mobileChecked: false,
            verifyCodeSend: 0,
            loading: true,
            sendingSMS: false,
            formData: {
                rememberMe: false
            },
            logoImg: logo,

            clickTimes: 0
        };
    },
    mounted() {
        this.loading = false;
        localStorage.setItem("token", undefined);
        localStorage.clear();
    },
    methods: {
        sendVerifyCode() {
            let me = this,
                mobile = this.formData.mobile;

            this.sendingSMS = true;
            this.axios.get("/common/verifyCode/sendSms/Login/" + mobile).then(() => {
                this.$message.success("验证码发送成功，请注意查收");
                me.verifyCodeSend = 60;
                this.sendingSMS = false;

                let t = setInterval(() => {
                    me.verifyCodeSend -= 1;
                    if(me.verifyCodeSend <= 0) {
                        clearInterval(t);
                    }
                }, 1000);
            }, () => {this.sendingSMS = false;});
        },
        checkMobile() {
            let regex = /1[3-9]\d{9}/;
            this.mobileChecked = regex.test(this.formData.mobile);
        },
        login() {

            localStorage.clear();

            let me = this,
                data = {
                    loginType: this.passwordLogin ? "password" : "verifyCode",
                    mobile: this.formData.mobile,
                    roleType: this.roleType,
                    rememberMe: this.formData.rememberMe,
                    code: this.formData.code,
                    password: this.formData.password
                };

            me.loading = true;
            this.axios.post("/org/session", data).then((res) => {
                if(!res.token) {
                    me.$message.error("登陆失败");
                    return;
                }
                me.$message.success("登陆成功");
                localStorage.setItem("token", res.token);
                location.hash = "#" + window.location.href.split("#")[1];
                location.reload();
            }, () => {
                me.loading = false;
            }).then(() => {
                me.loading = false;
            });
        },
        showDebug() {
            if(this.clickTimes < 10) {
                this.clickTimes += 1;
                return;
            }

            const el = window.electronInstance;

            if(el && el.ipcRenderer) {
                el.ipcRenderer.send("open-dev-tools");
                this.clickTimes = 0;
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .main-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100vw;
        flex-direction: row;
    }
    .login-container {
        max-width: 90%;
        width: 400px;
    }

    .login-bg{
        width: 100%;
        height: 100%;
        background-color: #f1f1f1;
        position: absolute;
        left:0;top:0;
        z-index: -1;
    }

    .operations-container {
        display: flex;
        justify-content: space-between;
    }
</style>
