import BaseModel from "../../../common/baseModel";

export default class PermissionModel extends BaseModel {

    name = "权限节点";
    api = "/org/permission";

    fields() {
        return [

    ];
}

}
